export const getRoutes = client => ({
    fetchCurrentUser() {
        return client.get('users/me')
    },
    // ---------------------------------
    getUsers(params) {
        return client.get('users', { params: params })
    },
    getUser(userId) {
        return client.get(`users/${userId}`)
    },
    saveUser(data) {
        return client.post('users', data)
    },
    updateUser(userId, data) {
        return client.patch(`users/${userId}`, data)
    }
})
