<template>
    <div>
        <h1 class="title">Suscripciones</h1>
        <div class="is-flex is-align-items-flex-end is-justify-content-space-between mb-6">
            <search-block @search="loadSubscriptions" @clear="loadSubscriptions" />
            <button @click="promptMassSend()" :disabled="isLoading" class="button is-primary" v-if="hasSubscriptions">
                Añadir Entrega Masiva
            </button>
        </div>
        <div class="columns is-multiline">
            <div class="column is-12">
                <v-loader v-if="isLoading" />
                <b-table
                    paginated
                    backend-pagination
                    :total="pagination.total"
                    :per-page="pagination.per_page"
                    @page-change="onPageChange"
                    :data="subscriptions"
                >
                    <b-table-column field="id" label="ID" width="40" numeric v-slot="{ row }">
                        {{ row.id }}
                    </b-table-column>
                    <b-table-column field="customer" label="Cliente" v-slot="{ row }">
                        <p>
                            <router-link
                                class="has-text-info"
                                :to="{
                                    name: 'customer_detail',
                                    params: { id: row.customer.id }
                                }"
                                >{{ row.customer.full_name }}</router-link
                            >
                        </p>
                        <small>{{ row.customer.email }}</small>
                    </b-table-column>
                    <b-table-column field="name" label="Suscripción" v-slot="{ row }">
                        {{ row.name }}
                    </b-table-column>
                    <b-table-column field="renovations" label="Ciclos de Renovacion" v-slot="{ row }">
                        <p>{{ getRenovations(row.renovations) }}</p>
                    </b-table-column>
                    <b-table-column field="products_sent" label="Productos Enviados" v-slot="{ row }">
                        <p>{{ getSentProducts(row.products_sent) }}</p>
                    </b-table-column>
                    <b-table-column field="status" label="Estado" v-slot="{ row }">
                        <span
                            class="tag is-light"
                            :class="{
                                'is-success': row.stripe_status === 'active',
                                'is-warning': row.stripe_status === 'trialing',
                                'is-danger':
                                    row.stripe_status === 'canceled' ||
                                    row.stripe_status === 'past_due' ||
                                    row.stripe_status === 'unpaid' ||
                                    row.stripe_status === 'incomplete' ||
                                    row.stripe_status === 'incomplete_expired'
                            }"
                        >
                            {{ row.stripe_status | parseStripeSubscriptionStatus }}
                        </span>
                    </b-table-column>
                    <b-table-column field="ends_at" label="Expiración" v-slot="{ row }">
                        {{ row.ends_at | formatDate }}
                    </b-table-column>
                    <b-table-column label="Acciones" v-slot="{ row }">
                        <div class="buttons has-addons">
                            <button
                                type="button"
                                class="button is-small is-danger is-light"
                                v-if="row.stripe_status === 'active'"
                                @click="promptCancel(row.id)"
                            >
                                Cancelar
                            </button>
                            <button
                                class="button is-small is-info is-light"
                                v-if="row.stripe_status === 'active'"
                                @click="promptSend(row.id)"
                            >
                                Añadir Entrega
                            </button>
                            <!--              <router-link class="button is-small is-info is-light" :to="{ name: 'ticket_details', params: { id: row.id } }">Ver detalles</router-link>-->
                        </div>
                    </b-table-column>
                </b-table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SubscriptionsList',
    data() {
        return {
            isLoading: false,
            pagination: {
                page: 1,
                per_page: 10,
                total: 0
            },
            subscriptions: []
        };
    },
    methods: {
        onPageChange(page) {
            this.pagination.page = page;
            this.loadSubscriptions();
        },
        loadSubscriptions(search = '') {
            this.isLoading = true;
            this.$api.subscriptions
                .getSubscriptions({
                    page: this.pagination.page,
                    perPage: this.pagination.perPage,
                    q: search
                })
                .then((res) => {
                    const { data, ...pagination } = res;
                    this.subscriptions = data;
                    this.pagination = pagination;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        getRenovations(cycles) {
            return cycles <= 1 ? 'Sin Renovaciones' : `${row.renovations} Ciclos`;
        },
        getSentProducts(items) {
            return items <= 0 ? 'No se han entregado productos' : `${items} entregados en total`;
        },
        promptSend(id) {
            this.$buefy.dialog.prompt({
                message: `Cuantos envios se realizaron?`,
                inputAttrs: {
                    type: 'number',
                    placeholder: 'Ingrese la cantidad de envios',
                    value: '0',
                    min: 0
                },
                trapFocus: true,
                onConfirm: (value) => this.sendProducts(id, value)
            });
        },
        promptMassSend() {
            this.$buefy.dialog.prompt({
                message: `Cuantos envios se realizaron?`,
                inputAttrs: {
                    type: 'number',
                    placeholder: 'Ingrese la cantidad de envios',
                    value: '0',
                    min: 0
                },
                trapFocus: true,
                onConfirm: (value) => this.sendMassProducts(value)
            });
        },
        sendMassProducts(amount) {
            this.isLoading = true;
            this.$buefy.dialog.confirm({
                title: 'Entregas De Productos',
                message: `Se agregaran <b>${amount} Entregas de productos</b> a todas las subscripciones activas, esta seguro?`,
                confirmText: 'Agregar Entregas',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => {
                    this.$buefy.toast.open(`Se Añadieron ${amount} entregas a todas las subscripciones`);
                    this.$api.subscriptions
                        .massSendProducts({
                            amount
                        })
                        .finally(() => {
                            this.isLoading = false;
                            this.loadSubscriptions();
                        });
                }
            });
            this.$api.subscriptions
                .massSendProducts({
                    amount
                })
                .finally(() => {
                    this.isLoading = false;
                    this.loadSubscriptions();
                });
        },
        sendProducts(id, amount) {
            this.isLoading = true;
            this.$api.subscriptions
                .sendProducts(id, {
                    amount
                })
                .finally(() => {
                    this.isLoading = false;
                    this.loadSubscriptions();
                });
        },
        promptCancel(id) {
            this.$buefy.dialog.confirm({
                title: 'Cancelando Subscripcion',
                message: 'Esta seguro de que desea <b>cancelar</b> esta subscripcion?',
                confirmText: 'Cancelar Subscripcion',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => this.cancelSubscription(id)
            });
        },
        cancelSubscription(id) {
            this.isLoading = true;
            this.$api.subscriptions
                .cancel(id)
                .then(() => this.$buefy.toast.open('La subscripcion se cancelo satisfactoriamente'))
                .finally(() => {
                    this.isLoading = false;
                    this.loadSubscriptions();
                });
        }
    },
    computed: {
        hasSubscriptions() {
            return this.subscriptions.length > 0;
        }
    },
    mounted() {
        this.loadSubscriptions();
    }
};
</script>
