<template>
  <div>
    <h1 class="title">Editar usuario</h1>
    <div class="notification is-danger is-light" v-if="errors && errors.length">
      <ul>
        <li v-for="error in errors">{{ error }}</li>
      </ul>
    </div>
    <v-loader v-if="isLoading"/>
    <template v-else-if="user">
      <h2 class="subtitle">{{ user.full_name }}</h2>
      <user-form :loading="isLoading" :user="user" @save="onSave" is-edit/>
    </template>
  </div>
</template>

<script>
export default {
  name: 'EditUser',
  data() {
    return {
      isLoading: false,
      errors: null,
      user: null
    }
  },
  computed: {
    userId() {
      return this.$route.params.id;
    }
  },
  methods: {
    onSave() {
      this.isLoading = true;
      this.$api.users.updateUser(this.userId, this.user)
      .then(() => {
        this.$buefy.toast.open({
          message: 'Usuario guardado',
          type: 'is-success'
        })
        this.$router.replace('/users')
      })
      .catch(e => {
        const { data } = e.response;
        this.$buefy.toast.open({
          message: 'Ocurrió un error',
          type: 'is-danger'
        })

        if (data) {
          const { errors } = data;
          this.errors = Object.values(errors).reduce((prev, x) => prev.concat(x), []);
        }
      })
      .finally(() => {
        this.isLoading = false;
      })
    },
    fetchUser() {
      this.isLoading = true;
      this.$api.users.getUser(this.userId)
      .then(user => {
        this.user = user;
        const { roles } = user;
        this.user.role = roles[0].name;
      })
      .finally(() => {
        this.isLoading = false;
      })
    }
  },
  mounted() {
    this.fetchUser();
  }
}
</script>
