<template>
    <div>
        <h1 class="title">Reporte General de Suscripciones</h1>
        <div class="columns is-multiline is-vcentered">
            <div class="column is-8">
                <div class="columns is-multiline">
                    <div class="column is-8">
                        <b-field label="Tipo de filtro">
                            <b-select v-model="filter" placeholder="Seleccione un filtro">
                                <option v-for="option in filters" :value="option.value" :key="option.value">
                                    {{ option.title }}
                                </option>
                            </b-select>
                        </b-field>
                    </div>
                    <div v-if="filter" class="column is-12">
                        <b-field label="Rango de generacion">
                            <b-datepicker
                                placeholder="Click to select..."
                                v-model="dates"
                                :min-date="minDate"
                                :max-date="maxDate"
                                range
                            >
                            </b-datepicker>
                        </b-field>
                    </div>
                    <div
                        class="column is-flex-direction-column is-align-items-center has-text-centered"
                        :class="{ 'is-6': hasSubscriptions, 'is-12': !hasSubscriptions }"
                    >
                        <button @click="loadReport()" :disabled="!isValid || isLoading" class="button is-primary my-auto">
                            Generar Reporte
                        </button>
                    </div>
                    <div
                        v-if="hasSubscriptions"
                        class="column is-flex-direction-column is-align-items-center has-text-centered is-6"
                    >
                        <button :disabled="!isValid || isLoading" class="button is-primary my-auto" @click="downloadReport()">
                            Descargar Reporte Completo
                        </button>
                    </div>
                </div>
            </div>

            <div class="column is-12">
                <v-loader v-if="isLoading" />
                <b-table paginated :total="pagination.total" :per-page="pagination.per_page" :data="subscriptions">
                    <b-table-column field="id" label="#" width="40" numeric v-slot="{ row }">
                        {{ row.id }}
                    </b-table-column>
                    <b-table-column field="customer" label="Cliente" v-slot="{ row }">
                        <p>
                            <router-link
                                class="has-text-info"
                                :to="{
                                    name: 'customer_detail',
                                    params: { id: row.customer.id }
                                }"
                                >{{ row.customer.full_name }}</router-link
                            >
                        </p>
                        <small>{{ row.customer.email }}</small>
                    </b-table-column>
                    <b-table-column field="paidOn" label="Pagada El" v-slot="{ row }">
                        {{ row.paidOn | formatDate }}
                    </b-table-column>
                    <b-table-column field="endingAt" label="Finaliza El" v-slot="{ row }">
                        {{ row.endingAt | formatDate }}
                    </b-table-column>
                    <b-table-column field="finalProducts" label="Productos" v-slot="{ row }">
                        {{ row.finalProducts }}
                    </b-table-column>
                    <b-table-column field="payment" label="Metodo de Pago" v-slot="{ row }">
                        {{ row.payment }}
                    </b-table-column>
                    <b-table-column field="totalPaid" label="Total Pago" v-slot="{ row }">
                        {{ row.totalPaid | formatCurrency }}
                    </b-table-column>
                </b-table>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'GeneralSubscriptionsReport',
    data() {
        return {
            isLoading: false,
            pagination: {
                page: 1,
                per_page: 10,
                total: 0
            },
            dates: [],
            filter: null,
            subscriptions: [],
            unProcessed: [],
            filters: [
                { value: 'created_at', title: 'Fecha de Aprobacion' },
                { value: 'ends_at', title: 'Fecha de finalizacion' }
            ]
        };
    },
    methods: {
        loadReport() {
            this.isLoading = true;
            this.$api.reports
                .getGeneralSubscriptions({
                    selector: this.filter,
                    initial_date: this.finalInitial,
                    ending_date: this.finalEnding
                })
                .then((res) => {
                    const { subscriptions } = res;
                    this.unProcessed = [];
                    this.unProcessed = subscriptions;
                    this.subscriptions = [];
                    this.processReport();
                })
                .catch((error) => {
                    this.unProcessed = [];
                    this.subscriptions = [];
                    console.error(error);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        downloadReport() {
            this.isLoading = true;
            this.$api.reports.downloads
                .getGeneralSubscriptions({
                    selector: this.filter,
                    initial_date: this.finalInitial,
                    ending_date: this.finalEnding
                })
                .then((res) => {
                    const { download_link } = res;
                    this.download(download_link);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        processReport() {
            this.isLoading = true;
            this.subscriptions = [];
            for (let index = 0; index < this.unProcessed.length; index++) {
                const sub = this.unProcessed[index];
                const customer = sub.customer;
                const paidOn = sub.paid_on;
                const endingAt = sub.ends_at;
                const total = sub.order.total;
                const payment = `${sub.payment_method.brand} ****${sub.payment_method.last_four} Exp: ${sub.payment_method.exp_date}`;
                const products = [];
                sub.subscription_products.forEach((product) => {
                    products.push(product.sku);
                });
                const finalProducts = products.join(',');

                this.subscriptions.push({
                    id: index + 1,
                    customer,
                    paidOn,
                    endingAt,
                    totalPaid: total,
                    payment,
                    finalProducts
                });
            }
            this.isLoading = false;
        },
        download(url) {
            const fileLink = document.createElement('a');

            fileLink.href = url;
            fileLink.setAttribute('download', 'report.xlsx');
            document.body.appendChild(fileLink);

            fileLink.click();
            fileLink.remove();
        }
    },
    computed: {
        today() {
            return new Date();
        },
        minDate() {
            return new Date(this.today.getFullYear() - 2, this.today.getMonth(), this.today.getDate());
        },
        maxDate() {
            return this.filter === 'ends_at'
                ? new Date(this.today.getFullYear() + 5, this.today.getMonth(), this.today.getDate())
                : this.today;
        },
        finalInitial() {
            return this.dates.length > 0 ? this.dates[0] : null;
        },
        finalEnding() {
            return this.dates.length > 0 ? this.dates[1] : null;
        },
        isValid() {
            return this.filter && this.finalInitial && this.finalEnding;
        },
        hasSubscriptions() {
            return this.subscriptions.length > 0;
        }
    },
    watch: {
        dates(newDates) {
            if (newDates.length <= 0) this.subscriptions = [];
        }
    }
};
</script>
