<template>
  <div>
    <h1 class="title">Crear producto</h1>
    <div class="notification is-danger is-light" v-if="errors && errors.length">
      <ul>
        <li v-for="error in errors">{{ error }}</li>
      </ul>
    </div>
    <product-form :loading="isSaving" :product="productData" @save="onSave"/>
  </div>
</template>

<script>
export default {
  name: 'CreateProduct',
  data() {
    return {
      isSaving: false,
      errors: null,
      productData: {
        name: '',
        sku: '',
        price: 0,
        type: 'recurrent',
        frequency: 1,
        price_per_edition: 0,
        version_type: "digital",
        total_editions: 1,
        brand: "vogue",
      }
    }
  },
  methods: {
    onSave() {
      this.isSaving = true;
      this.$api.products.saveProduct(this.productData)
      .then(data => {
        this.$buefy.toast.open({
          message: 'Producto guardado',
          type: 'is-success'
        })
        this.$router.replace('/products')
      })
      .catch(e => {
        const { data } = e.response;
        this.$buefy.toast.open({
          message: 'Ocurrió un error',
          type: 'is-danger'
        })

        if (data) {
          const { errors } = data;
          this.errors = Object.values(errors).reduce((prev, x) => prev.concat(x), []);
        }
      })
      .finally(() => {
        this.isSaving = false;
      })
    }
  }
}
</script>
