var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"title"},[_vm._v("Clientes")]),_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-6"},[_c('search-block',{on:{"search":_vm.loadCustomers,"clear":_vm.loadCustomers}})],1),_c('div',{staticClass:"column is-12"},[(_vm.isLoading)?_c('v-loader'):_vm._e(),_c('b-table',{attrs:{"paginated":"","backend-pagination":"","total":_vm.pagination.total,"per-page":_vm.pagination.per_page,"data":_vm.customers},on:{"page-change":_vm.onPageChange}},[_c('b-table-column',{attrs:{"field":"id","label":"ID","width":"40","numeric":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.id)+" ")]}}])}),_c('b-table-column',{attrs:{"field":"full_name","label":"Nombre"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.full_name)+" ")]}}])}),_c('b-table-column',{attrs:{"field":"email","label":"Correo electrónico"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.email)+" ")]}}])}),_c('b-table-column',{attrs:{"field":"phone","label":"Teléfono"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.phone)+" ")]}}])}),_c('b-table-column',{attrs:{"label":"Acciones"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('router-link',{staticClass:"button is-info is-light is-small",attrs:{"to":{ name: 'customer_detail', params: { id: row.id } }}},[_vm._v("Ver detalles")])]}}])})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }