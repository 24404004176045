<template>
  <ValidationProvider :name="vvName" :rules="rules" :vid="vid" ref="validator"
                      v-slot="{ errors, valid, invalid, validated, required }" tag="div">
    <div class="field">
      <label class="label" :for="fieldId">
        <slot></slot>
        <span v-if="required">*</span>
      </label>
      <div class="control">
        <textarea v-model="innerValue"
                class="textarea"
                :class="{'is-success': validated && valid, 'is-danger': validated && invalid}"
                v-bind="fieldAttributes" v-if="fieldType === 'textarea'"></textarea>
        <input v-model="innerValue"
               class="input"
               :class="{'is-success': validated && valid, 'is-danger': validated && invalid}"
               v-bind="fieldAttributes" v-else>
      </div>
      <transition name="slide">
        <span class="help is-danger" v-if="errors.length">{{ errors[0] }}</span>
      </transition>
    </div>
  </ValidationProvider>
</template>

<script>
import InputMixin from "./InputMixin";

export default {
  inheritAttrs: false,
  name: 'VInput',
  mixins: [InputMixin],
  props: {
    vvName: {
      type: String
    },
    value: {
      type: [String, Number]
    },
    rules: {
      type: [String, Object]
    },
    vid: {
      type: String
    }
  },
  data() {
    return {
      innerValue: ''
    }
  },
  computed: {
    fieldAttributes() {
      const {id, type, ...attrs} = this.$attrs;

      const attributes = {
        ...attrs,
        id: this.fieldId,
      };

      return this.fieldType === 'textarea' ? attributes : {
        ...attributes,
        type: this.fieldType
      }
    },
    fieldType() {
      return this.$attrs.type ? this.$attrs.type : 'text';
    }
  },
  methods: {
    resetValidation() {
      this.$refs.validator.reset();
    }
  },
  watch: {
    value(val) {
      this.innerValue = val;
    },
    innerValue(val) {
      this.$emit('input', val)
    }
  },
  beforeMount() {
    if (this.value !== undefined) {
      this.innerValue = this.value;
    }
  }
}
</script>
