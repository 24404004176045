const filters = {
    formatCurrency(value, currency = 'MXN') {
        return Intl.NumberFormat('es-MX',
            {
                style: 'currency',
                currency: currency,
                currencyDisplay: 'symbol',
                minimumFractionDigits: 0
            }).format(value)
    },
    parseProductType(value) {
        switch(value) {
            case 'simple':
                return 'Simple';
            case 'recurrent':
                return 'Recurrente';
            case 'bundle':
                return 'Bundle'
            default:
                return value;
        }
    },
    parseUserRoles(roles) {
        if (!roles) return '';
        return roles.map(role => {
            switch (role.name) {
                case 'agent':
                    return 'Agente'
                case 'admin':
                    return 'Admin'
                default:
                    return role.name;
            }
        }).join(', ');
    },
    parseTicketStatus(status) {
        switch (status) {
            case 'open':
                return 'Abierto'
            case 'closed':
                return 'Cerrado'
            default:
                return status;
        }
    },
    parseOrderStatus(status) {
        switch(status) {
            case 'approved':
                return 'Aprobada';
            case 'pending':
                return 'Pendiente';
            case 'rejected':
                return 'Rechazada'
            default:
                return status;
        }
    },
    parseStripeSubscriptionStatus(status) {
        switch(status) {
            case 'active':
                return 'Activa';
            case 'past_due':
                return 'Pago vencido';
            case 'unpaid':
                return 'Sin pagar';
            case 'canceled':
                return 'Cancelada';
            case 'incomplete':
                return 'Incompleta';
            case 'incomplete_expired':
                return 'Expirada';
            case 'trialing':
                return 'Periodo de prueba'
            default:
                return status;
        }
    },
    formatDate(date, includeTime = false) {
        const dateObj = new Date(date);
        const locale = 'es-MX';
        const options = {
            dateStyle: 'medium'
        };
        return includeTime ?
            dateObj.toLocaleString(locale) : dateObj.toLocaleDateString( locale, options)
    }
}
export default filters;
