export const getRoutes = (client) => ({
    getOrdersCount() {
        return client.get('reports/orders-count');
    },
    getOrdersByAgent() {
        return client.get('reports/orders-by-agent');
    },
    getOrdersByCountry() {
        return client.get('reports/orders-by-country');
    },
    getTicketsCount() {
        return client.get('reports/tickets-count');
    },
    getGeneralSubscriptions(data) {
        return client.post('reports/subscriptions/general', data);
    },
    getGeneralOrders(data) {
        return client.post('reports/orders/general', data);
    },
    getPayoutItemizedParams() {
        return client.get('reports/payouts/params/itemized');
    },
    generateItemizedPayouts(data) {
        return client.post('reports/payouts/generate/itemized', data);
    },

    /* Unified report endpoints */
    getGeneralUnifiedReportStatus() {
        return client.get('reports/unified/general/status');
    },
    searchGeneralUnifiedReport(year, month) {
        return client.get(`reports/unified/general/${year}/${month}`);
    },
    createItemizedUnifiedReport(data) {
        return client.post(`reports/unified/itemized`, data);
    },
    searchItemizedUnifiedReport(data) {
        return client.post(`reports/unified/itemized/search`, data);
    },

    downloads: {
        getSalesReport() {
            return client.get('reports/downloads/sales', {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: '*'
                }
            });
        },
        getGeneralSubscriptions(data) {
            return client.post('reports/subscriptions/general/download', data);
        },
        getGeneralOrders(data) {
            return client.post('reports/orders/general/download', data);
        },
        getItemizedPayouts(data) {
            return client.post('reports/payouts/download/itemized/report', data);
        }
    }
});
