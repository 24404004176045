var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"title"},[_vm._v(_vm._s(_vm.tableTitle))]),_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-12"},[(_vm.isLoading)?_c('v-loader'):_vm._e(),_c('b-table',{attrs:{"paginated":"","backend-pagination":"","backend-filtering":"","total":_vm.pagination.total,"per-page":_vm.pagination.per_page,"data":_vm.orders},on:{"page-change":_vm.onPageChange}},[_c('b-table-column',{attrs:{"field":"id","label":"ID","width":"40","numeric":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.id)+" ")]}}])}),_c('b-table-column',{attrs:{"field":"status","label":"Estado"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"tag is-light",class:{
                  'is-success': row.status === 'approved',
                  'is-warning': row.status === 'pending',
                  'is-danger': row.status === 'rejected'}},[_vm._v(" "+_vm._s(_vm._f("parseOrderStatus")(row.status))+" ")])]}}])}),_c('b-table-column',{attrs:{"field":"customer","label":"Cliente"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var row = ref.row;
return [_c('p',[_vm._v(_vm._s(row.customer.full_name))]),_c('small',[_vm._v(_vm._s(row.customer.email))])]}}])}),_c('b-table-column',{attrs:{"field":"total","label":"Total","numeric":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("formatCurrency")(row.total))+" ")]}}])}),_c('b-table-column',{attrs:{"field":"created_at","label":"Fecha"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(row.created_at))+" ")]}}])}),_c('b-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var row = ref.row;
return [_c('router-link',{staticClass:"button is-info is-light is-small",attrs:{"to":{ name: 'order_detail', params: { id: row.id } }}},[_vm._v("Ver detalles")])]}}])})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }