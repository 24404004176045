export const getRoutes = client => ({
  login(credentials) {
    return client.post('login', credentials);
  },
  logout() {
    return client.post('logout');
  },
  askPasswordReset(data) {
    return client.post('forgot-password', data)
  },
  resetPassword(data) {
    return client.post('reset-password', data)
  }
})
