<template>
  <div>
    <h1 class="title">Reporte General de Ordenes</h1>
    <div class="columns is-multiline is-vcentered">
      <div class="column is-8">
        <div class="columns is-multiline">
          <div class="column is-8">
            <b-field label="Tipo de filtro">
              <b-select v-model="filter" placeholder="Seleccione un filtro">
                <option
                  v-for="option in filters"
                  :value="option.value"
                  :key="option.value"
                >
                  {{ option.title }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div v-if="filter" class="column is-12">
            <b-field label="Rango de generacion">
              <b-datepicker
                placeholder="Click to select..."
                v-model="dates"
                :min-date="minDate"
                :max-date="today"
                range
              >
              </b-datepicker>
            </b-field>
          </div>
          <div
            class="column is-flex-direction-column is-align-items-center has-text-centered"
            :class="{ 'is-6': hasOrders, 'is-12': !hasOrders }"
          >
            <button
              @click="loadReport()"
              :disabled="!isValid || isLoading"
              class="button is-primary my-auto"
            >
              Generar Reporte
            </button>
          </div>
          <div
            v-if="hasOrders"
            class="column is-flex-direction-column is-align-items-center has-text-centered is-6"
          >
            <button
              :disabled="!isValid || isLoading"
              class="button is-primary my-auto"
              @click="downloadReport()"
            >
              Descargar Reporte Completo
            </button>
          </div>
        </div>
      </div>

      <div class="column is-12">
        <v-loader v-if="isLoading" />
        <b-table
          paginated
          :total="pagination.total"
          :per-page="pagination.per_page"
          :data="orders"
        >
          <b-table-column
            field="id"
            label="#"
            width="40"
            numeric
            v-slot="{ row }"
          >
            {{ row.id }}
          </b-table-column>
          <b-table-column field="customer" label="Cliente" v-slot="{ row }">
            <p>
              <router-link
                class="has-text-info"
                :to="{
                  name: 'customer_detail',
                  params: { id: row.customer.id }
                }"
                >{{ row.customer.full_name }}</router-link
              >
            </p>
            <small>{{ row.customer.email }}</small>
          </b-table-column>
          <b-table-column field="address" label="Direccion" v-slot="{ row }">
            <p>
              {{ row.address.city.name }},
              {{ row.address.city.state.country.name }}
            </p>
            <p>{{ row.address.address }}</p>
            <p>{{ row.address.postal_codes }}</p>
            <p>{{ row.address.notes }}</p>
          </b-table-column>
          <b-table-column field="products" label="Productos" v-slot="{ row }">
            <template v-for="(product, index) in row.products">
              <p :key="`sku-${index}`">{{ product.sku }}</p>
              <p :key="`info-${index}`">
                <span>{{ product.type | parseProductType }}</span>
              </p>
            </template>
          </b-table-column>
           <b-table-column field="totalPaid" label="Total Pago" v-slot="{ row }">
            {{ row.totalPaid | formatCurrency }}
          </b-table-column>
           <b-table-column field="paidOn" label="Pagada El" v-slot="{ row }">
            {{ row.paidOn | formatDate }}
          </b-table-column>
          <b-table-column
            field="payment"
            label="Metodo de Pago"
            v-slot="{ row }"
          >
            {{ row.payment }}
          </b-table-column>
        </b-table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "GeneralOrdersReport",
  data() {
    return {
      isLoading: false,
      pagination: {
        page: 1,
        per_page: 10,
        total: 0
      },
      dates: [],
      filter: null,
      orders: [],
      unProcessed: [],
      filters: [{ value: "created_at", title: "Fecha de Aprobacion" }]
    };
  },
  methods: {
    loadReport() {
      this.isLoading = true;
      this.$api.reports
        .getGeneralOrders({
          selector: this.filter,
          initial_date: this.finalInitial,
          ending_date: this.finalEnding
        })
        .then(res => {
          const { orders } = res;
          this.unProcessed = orders;
          this.processReport();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    downloadReport() {
      this.isLoading = true;
      this.$api.reports.downloads
        .getGeneralOrders({
          selector: this.filter,
          initial_date: this.finalInitial,
          ending_date: this.finalEnding
        })
        .then(res => {
          const { download_link } = res;
          this.download(download_link);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    processReport() {
      this.isLoading = true;
      this.orders.length = 0;
      for (let index = 0; index < this.unProcessed.length; index++) {
        const order = this.unProcessed[index];
        const customer = order.customer;
        const address = order.order_address;
        const products = order.products;
        const totalPaid = order.order.total;
        const payment = `${order.payment_method.brand} ****${order.payment_method.last_four} Exp: ${order.payment_method.exp_date}`;
        const paidOn = order.paid_on;


        this.orders.push({ id: index + 1, customer, address, products, totalPaid, payment, paidOn });
      }
      this.isLoading = false;
    },
    download(url) {
      const fileLink = document.createElement("a");

      fileLink.href = url;
      fileLink.setAttribute("download", "report.xlsx");
      document.body.appendChild(fileLink);

      fileLink.click();
      fileLink.remove();
    }
  },
  computed: {
    today() {
      return new Date();
    },
    minDate() {
      return new Date(
        this.today.getFullYear() - 2,
        this.today.getMonth(),
        this.today.getDate()
      );
    },
    finalInitial() {
      return this.dates.length > 0 ? this.dates[0] : null;
    },
    finalEnding() {
      return this.dates.length > 0 ? this.dates[1] : null;
    },
    isValid() {
      return this.filter && this.finalInitial && this.finalEnding;
    },
    hasOrders() {
      return this.orders.length > 0;
    }
  }
};
</script>
