const colorsByStatus = {
    'success': 'hsl(141, 53%, 53%)',
    'warning': 'hsl(48, 100%, 67%)',
    'danger': 'hsl(348, 100%, 61%)'
}

const translucentColors = {
    info: 'hsl(204, 86%, 53%, 75%)'
}

const colors = {
    ...colorsByStatus,
    info: 'hsl(204, 86%, 53%)',
    blue: 'hsl(217, 71%, 53%)',
    purple: 'hsl(271, 100%, 71%)',
    turquoise: 'hsl(171, 100%, 41%)',
    orange: 'hsl(14, 100%, 53%)',
    grey: 'hsl(0, 0%, 30%)',
}

const getRandomColors = (count = 1) => {
    let usedKeys = [];
    let selectedColors = [];

    while (selectedColors.length < count) {
        const index = Math.floor(Math.random() * (Object.keys(colors).length - 1));

        if (!usedKeys.includes(Object.keys(colors)[index])) {
            selectedColors.push(Object.values(colors)[index]);
        }
    }

    return selectedColors;
}

export { colorsByStatus, colors, translucentColors, getRandomColors }