<template>
    <div>
        <h1 class="title">Clientes</h1>
        <div class="columns is-multiline">
            <div class="column is-6">
                <search-block @search="loadCustomers" @clear="loadCustomers" />
            </div>
            <div class="column is-12">
                <v-loader v-if="isLoading" />
                <b-table
                    paginated
                    backend-pagination
                    :total="pagination.total"
                    :per-page="pagination.per_page"
                    @page-change="onPageChange"
                    :data="customers"
                >
                    <b-table-column field="id" label="ID" width="40" numeric v-slot="{ row }">
                        {{ row.id }}
                    </b-table-column>
                    <b-table-column field="full_name" label="Nombre" v-slot="{ row }">
                        {{ row.full_name }}
                    </b-table-column>
                    <b-table-column field="email" label="Correo electrónico" v-slot="{ row }">
                        {{ row.email }}
                    </b-table-column>
                    <b-table-column field="phone" label="Teléfono" v-slot="{ row }">
                        {{ row.phone }}
                    </b-table-column>
                    <b-table-column label="Acciones" v-slot="{ row }">
                        <router-link
                            class="button is-info is-light is-small"
                            :to="{ name: 'customer_detail', params: { id: row.id } }"
                            >Ver detalles</router-link
                        >
                    </b-table-column>
                </b-table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SubscriptionsList',
    data() {
        return {
            isLoading: false,
            pagination: {
                page: 1,
                per_page: 10,
                total: 0
            },
            customers: []
        };
    },
    methods: {
        onPageChange(page) {
            this.pagination.page = page;
            this.loadCustomers();
        },
        loadCustomers(search = '') {
            this.isLoading = true;
            this.$api.customers
                .getCustomers({
                    page: this.pagination.page,
                    perPage: this.pagination.perPage,
                    q: search
                })
                .then((res) => {
                    const { data, ...pagination } = res;
                    this.customers = data;
                    this.pagination = pagination;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        }
    },
    mounted() {
        this.loadCustomers();
    }
};
</script>
