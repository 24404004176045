<template>
  <ValidationObserver v-slot="{ valid, invalid }">
    <form @submit.prevent="save">
      <div class="columns">
        <div class="column is-10 is-relative">
          <v-loader v-if="loading"></v-loader>
          <div class="columns is-multiline">
            <div class="column is-6">
              <v-input
                rules="required"
                vv-name="Nombre"
                v-model="productData.name"
                >Nombre</v-input
              >
            </div>
            <div class="column is-6">
              <v-input rules="required" vv-name="Sku" v-model="productData.sku"
                >Sku</v-input
              >
            </div>
            <div class="column is-6">
              <v-input
                rules="required"
                vv-name="Precio"
                type="number"
                :formatting="{
                  numeral: true,
                  numeralThousandsGroupStyle: 'thousand',
                }"
                v-model="productData.price"
                >Precio</v-input
              >
            </div>
            <div class="column is-6">
              <v-select
                rules="required"
                vv-name="Tipo"
                v-model="productData.type"
              >
                <span>Tipo</span>
                <template slot="options">
                  <option value="simple">Simple</option>
                  <option value="recurrent">Recurrente</option>
                  <option value="bundle">Bundle</option>
                </template>
              </v-select>
            </div>
            <div class="column is-6">
              <v-input
                rules="required"
                vv-name="Precio cubierto por edicion"
                type="number"
                :formatting="{
                  numeral: true,
                  numeralThousandsGroupStyle: 'thousand',
                }"
                v-model="productData.price_per_edition"
                >Precio cubierto por edicion</v-input
              >
            </div>
            <div class="column is-6">
              <v-select
                rules="required"
                vv-name="Version"
                v-model="productData.version_type"
              >
                <span>Version</span>
                <template slot="options">
                  <option value="digital">Digital</option>
                  <option value="digital+print">Digital e Impreso</option>
                </template>
              </v-select>
            </div>
             <div class="column is-6">
                <v-input
                  rules="required|min_value:1"
                  vv-name="Ediciones totales"
                  type="number"
                  v-model="productData.total_editions"
                  >Ediciones Totales</v-input
                >
              </div>
              <div class="column is-6">
              <v-select
                rules="required"
                vv-name="Marca"
                v-model="productData.brand"
              >
                <span>Marca</span>
                <template slot="options">
                  <option value="vogue">Vogue</option>
                  <option value="gq">GQ</option>
                  <option value="ad">AD</option>
                  <option value="courses">Cursos</option>
                </template>
              </v-select>
            </div>
            <template v-if="productData.type === 'recurrent'">
              <div class="column is-12 is-paddingless">
                <hr />
              </div>
              <div class="column is-4">
                <v-input
                  rules="required|min_value:1"
                  vv-name="Frecuencia"
                  type="number"
                  v-model="productData.frequency"
                  >Frecuencia</v-input
                >
              </div>
            </template>
            <div class="column is-12">
              <button
                class="button is-primary"
                type="submit"
                :disabled="invalid"
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
export default {
  name: "ProductForm",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    product: {
      type: Object,
      default() {
        return {
          name: "",
          sku: "",
          price: 0,
          type: "recurrent",
          frequency: 1,
          price_per_edition: 0,
          version_type: "digital",
          total_editions: 1,
          brand: "vogue",
        };
      },
    },
  },
  data() {
    return {
      productData: null,
    };
  },
  methods: {
    save() {
      this.$emit("update:product", this.productData);
      this.$emit("save");
    },
  },
  beforeMount() {
    this.productData = this.product;
    if (this.productData.type === "recurrent") {
      this.productData.frequency = this.$getProductFrequency(this.productData);
    }
  },
};
</script>
