<template>
  <nav class="navbar has-shadow">
    <div class="navbar-brand">
      <router-link to="/" class="navbar-item">
        <img src="../../assets/condenast_logo.png" alt="">
      </router-link>
    </div>
    <div class="navbar-menu">
      <div class="navbar-end">
        <div class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link">Hola, {{ profile.first_name }}</a>
          <div class="navbar-dropdown">
<!--            <router-link class="navbar-item" to="/profile">Editar perfil</router-link>-->
<!--            <router-link class="navbar-item" to="/change-password">Cambiar contraseña</router-link>-->
            <hr class="navbar-divider">
            <a role="button" class="navbar-item" @click="logout">Cerrar sesión</a>
          </div>
        </div>
        <div class="navbar-item"></div>
        <div class="navbar-item"></div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'VNavbar',
  computed: {
    ...mapState('user', ['profile'])
  },
  methods: {
    ...mapActions('user', ['logout'])
  }
}
</script>
