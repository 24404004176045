import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate';
import { email, min, min_value, confirmed } from 'vee-validate/dist/rules';
import es from 'vee-validate/dist/locale/es.json';

const setupValidation = Vue => {
    //  Localize
    localize('es', es);

    //  Register components
    Vue.component('ValidationProvider', ValidationProvider);
    Vue.component('ValidationObserver', ValidationObserver);

    //  Define rules
    extend('email', email)
    extend('min_value', min_value)
    extend('min', min)
    extend('confirmed', confirmed)
    extend('required', {
        validate(value) {
            return {
                required: true,
                valid: ['', null, undefined].indexOf(value) === -1
            };
        },
        computesRequired: true
    });

}

export { setupValidation }