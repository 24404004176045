<template>
  <aside class="menu">
    <template v-for="block in menu">
      <p class="menu-label">
        {{ block.title }}
      </p>
      <ul class="menu-list">
        <li v-for="item in block.routes" v-if="!item.hidden">
          <router-link :to="item.path">{{ typeof item.label === 'function' ? item.label(dataForSidebar) : item.label }}</router-link>
        </li>
      </ul>
    </template>
  </aside>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { sidebarMenu } from '@/utils/navigation';

export default {
  name: 'VSidebar',
  computed: {
    ...mapState('user', ['profile']),
    ...mapGetters('user', ['isAdmin']),
    dataForSidebar() {
      return {
        currentUser: this.profile,
        isAdmin: this.isAdmin
      }
    },
    menu() {
      const { roles : userRoles } = this.profile;

      if (!userRoles || !userRoles.length) {
        return [];
      }

      let routeGroups = sidebarMenu;

      return routeGroups.filter(group => {
        let groupRoutes = group.routes;

        groupRoutes = groupRoutes.filter(route => {
          const routeRoles = route.roles;

          if (!routeRoles) return true;

          for (let userRole of userRoles) {

            if (routeRoles.includes(userRole.name)) {
              return true;
            }
          }

          return false;
        });

        group.routes = groupRoutes;

        return groupRoutes.length > 0;
      })
    }
  }
}
</script>
