export default {
  inheritAttrs: false,
  computed: {
    fieldId() {
      // eslint-disable-next-line
      const { id, ...attrs } = this.$attrs;
      // eslint-disable-next-line
      return id ? id : `field-${this._uid}`;
    }
  }
}
