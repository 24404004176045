var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-4"},[(_vm.order)?_c('h1',{staticClass:"title"},[_vm._v("Órden #"+_vm._s(_vm.order.id))]):_c('b-skeleton',{attrs:{"size":"is-large"}})],1),_c('div',{staticClass:"column is-8 has-text-right"}),_c('div',{staticClass:"column is-12"},[(_vm.order)?_c('span',{staticClass:"tag is-medium is-light",class:{
                  'is-success': _vm.order.status === 'approved',
                  'is-warning': _vm.order.status === 'pending',
                  'is-danger': _vm.order.status === 'rejected'}},[_vm._v(" "+_vm._s(_vm._f("parseOrderStatus")(_vm.order.status))+" ")]):_c('b-skeleton',{attrs:{"size":"is-large"}})],1),_vm._m(0),_c('div',{staticClass:"column is-12"},[_c('div',{staticClass:"columns is-multiline"},[_vm._m(1),_c('div',{staticClass:"column is-6 has-text-right"},[(_vm.order)?_c('router-link',{staticClass:"button is-info is-light",attrs:{"to":{ name: 'customer_detail', params: { id: _vm.order.customer.id } }}},[_vm._v("Ver cliente")]):_vm._e()],1),_c('div',{staticClass:"column is-4"},[_c('span',{staticClass:"label"},[_vm._v("Nombre")]),(_vm.order)?_c('p',[_vm._v(_vm._s(_vm.order.customer.full_name))]):_c('b-skeleton')],1),_c('div',{staticClass:"column is-4"},[_c('span',{staticClass:"label"},[_vm._v("Correo electrónico")]),(_vm.order)?_c('a',{attrs:{"href":("mailto:" + (_vm.order.customer.email))}},[_vm._v(_vm._s(_vm.order.customer.email))]):_c('b-skeleton')],1),_c('div',{staticClass:"column is-4"},[_c('span',{staticClass:"label"},[_vm._v("Teléfono")]),(_vm.order)?_c('p',[_vm._v(_vm._s(_vm.order.customer.phone))]):_c('b-skeleton')],1),_c('div',{staticClass:"column is-4"},[_c('span',{staticClass:"label"},[_vm._v("Dirección")]),(_vm.order)?[_c('p',[_vm._v(_vm._s(_vm.order.address.address))]),_c('p',[_vm._v(_vm._s(_vm.order.address.postal_codes))]),_c('p',[_vm._v(_vm._s(_vm.order.address.notes))])]:_c('b-skeleton')],2)])]),_vm._m(2),_c('div',{staticClass:"column is-12"},[_c('div',{staticClass:"columns is-multiline"},[_vm._m(3),_c('div',{staticClass:"column is-12"},[_c('b-table',{attrs:{"loading":_vm.isLoading,"data":_vm.products}},[_c('b-table-column',{attrs:{"field":"id","label":"ID","width":"40","numeric":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var row = ref.row;
return [_vm._v(" "+_vm._s(row.id)+" ")]}}])}),_c('b-table-column',{attrs:{"field":"name","label":"Nombre"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var row = ref.row;
return [_vm._v(" "+_vm._s(row.name)+" ")]}}])}),_c('b-table-column',{attrs:{"field":"sku","label":"SKU"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var row = ref.row;
return [_vm._v(" "+_vm._s(row.sku)+" ")]}}])}),_c('b-table-column',{attrs:{"field":"price","label":"Precio"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("formatCurrency")(row.price))+" ")]}}])}),_c('b-table-column',{attrs:{"field":"type","label":"Tipo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm._f("parseProductType")(row.type)))]),_c('br'),(row.type === 'recurrent')?_c('span',{staticClass:"tag is-info is-light"},[_vm._v("Cada "+_vm._s(_vm.$getProductFrequency(row))+" meses")]):_vm._e()]}}])}),_c('b-table-column',{attrs:{"label":"Acciones"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var row = ref.row;
return [_c('div',{staticClass:"buttons has-addons"},[(!row.deleted_at)?_c('router-link',{staticClass:"button is-small is-primary",attrs:{"to":{ name: 'edit_product', params: { id: row.id } }}},[_vm._v("Ver")]):_vm._e()],1)]}}])})],1)],1)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column is-12 is-paddingless"},[_c('hr')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column is-6"},[_c('h2',{staticClass:"title is-4"},[_vm._v("Cliente")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column is-12 is-paddingless"},[_c('hr')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column is-12"},[_c('h2',{staticClass:"title is-5"},[_vm._v("Productos")])])}]

export { render, staticRenderFns }