<template>
  <div>
    <h1 class="title">Crear usuario</h1>
    <div class="notification is-danger is-light" v-if="errors && errors.length">
      <ul>
        <li v-for="error in errors">{{ error }}</li>
      </ul>
    </div>
    <user-form :loading="isSaving" :user="userData" @save="onSave"/>
  </div>
</template>

<script>
export default {
  name: 'CreateUser',
  data() {
    return {
      isSaving: false,
      errors: null,
      userData: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        password_confirmation: '',
        role: 'agent'
      }
    }
  },
  methods: {
    onSave() {
      this.isSaving = true;
      this.$api.users.saveUser(this.userData)
      .then(data => {
        this.$buefy.toast.open({
          message: 'Usuario guardado',
          type: 'is-success'
        })
        this.$router.replace('/users')
      })
      .catch(e => {
        const { data } = e.response;
        this.$buefy.toast.open({
          message: 'Ocurrió un error',
          type: 'is-danger'
        })

        if (data) {
          const { errors } = data;
          this.errors = Object.values(errors).reduce((prev, x) => prev.concat(x), []);
        }
      })
      .finally(() => {
        this.isSaving = false;
      })
    }
  }
}
</script>
