<template>
    <div id="endingReport" class="view-container">
        <v-loader v-if="isLoading" />
        <div class="report-title mb-6">
            <h1 class="title is-3">Reporte Unificado</h1>
        </div>
        <div class="generalReport box mb-6">
            <div class="reports-info mb-6">
                <h2 class="title is-4 mb-2 has-text-centered">Reporte General</h2>
                <div class="monthly-report-status mb-2" v-if="monthReport">
                    <ReportStatusCard :status-data="monthReport"></ReportStatusCard>
                </div>
                <div class="last-report-status" v-if="lastReport">
                    <h3 class="is-size-5 mb-2">
                        Ultimo Reporte Generado:
                        <b>{{ lastReport.info ? getMonthName(lastReport.info.month) : 'No disponible.' }}</b>
                    </h3>
                    <ReportStatusCard
                        v-if="lastReport.info"
                        :status-data="lastReport"
                        class="last-report-card"
                    ></ReportStatusCard>
                </div>
            </div>
            <div class="get-report">
                <h3 class="is-size-5 mb-4">Consultar Reportes</h3>
                <div v-if="dataAvailability" class="report-options">
                    <div class="month-selector has-addons m-0 mr-4 p-0">
                        <h5 class="title is-6">Fecha</h5>
                        <div class="selector__inputs">
                            <p class="control">
                                <span class="select">
                                    <select name="month" id="month" v-model="month">
                                        <option :value="month" v-for="month in monthOptions">
                                            {{ getMonthName(month) }}
                                        </option>
                                    </select>
                                </span>
                            </p>
                            <p class="control">
                                <span class="select">
                                    <select v-model="year" name="year" id="year">
                                        <option :value="year" v-for="year in yearOptions">{{ year }}</option>
                                    </select>
                                </span>
                            </p>
                        </div>
                    </div>
                    <button @click.prevent="searchGeneralReport" class="button is-primary action-btn">Consultar</button>
                </div>
                <span class="has-text-grey-light" v-else>No hay reportes generados.</span>
                <ReportStatusCard
                    v-if="reportSearchResult"
                    :status-data="reportSearchResult"
                    @cardClosed="resetGeneralReportSearch"
                ></ReportStatusCard>
            </div>
        </div>
        <div class="itemized-report box">
            <h2 class="title has-text-centered is-4 mb-6">Reporte por rango de Fechas</h2>
            <div class="column mb-6 p-0">
                <b-field label="Fechas" grouped>
                    <b-datepicker
                        class="column is-4 p-0"
                        placeholder="Click para seleccionar..."
                        :min-date="minDate"
                        :max-date="maxDate"
                        v-model="itemizedReport.range"
                        range
                    >
                    </b-datepicker>
                    <button
                        class="button is-primary"
                        @click="createItemizedReport()"
                        :disabled="!itemizedReport.range.length > 0"
                    >
                        Generar
                    </button>
                </b-field>
            </div>
            <h3 class="is-size-5 mb-4">Consultar Reporte</h3>
            <div class="column mb-6 p-0">
                <b-field label="Token" grouped>
                    <b-input class="column is-4 p-0" v-model="itemizedReport.searchToken"> </b-input>
                    <button class="button is-primary" @click="searchItemizedReport()" :disabled="!itemizedReport.searchToken">
                        Consultar
                    </button>
                </b-field>
            </div>
            <ReportStatusCard
                v-if="itemizedReport.itemizedSearchResult"
                :status-data="itemizedReport.itemizedSearchResult"
                @cardClosed="resetItemizedReportSearch"
            >
            </ReportStatusCard>
        </div>
    </div>
</template>
<script>
import ReportStatusCard from '../../components/reports/ReportStatusCard.vue';

export default {
    components: { ReportStatusCard },
    data() {
        return {
            isLoading: false,
            currency: '',
            selectedMonth: null,
            month: null,
            year: null,
            lastReport: null,
            monthReport: null,
            reportSearchResult: null,
            itemizedReport: {
                range: [],
                searchToken: null,
                itemizedSearchResult: null
            },
            dataAvailability: null
        };
    },
    methods: {
        searchGeneralReport() {
            this.isLoading = true;
            this.$api.reports
                .searchGeneralUnifiedReport(this.year, this.month)
                .then((res) => {
                    this.reportSearchResult = res;
                })
                .catch(() => {
                    this.$buefy.dialog.alert({
                        title: 'Error',
                        message: 'Error inesperado, por favor trate nuevamente',
                        type: 'is-danger',
                        hasIcon: true,
                        icon: 'times-circle',
                        iconPack: 'fas',
                        ariaRole: 'alertdialog',
                        ariaModal: true
                    });
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        getGeneralReportStatus() {
            this.isLoading = true;
            this.$api.reports
                .getGeneralUnifiedReportStatus()
                .then((res) => {
                    const { data_availability, last_report, month_report } = res;
                    this.lastReport = last_report;
                    this.monthReport = month_report;
                    this.dataAvailability = data_availability;
                })
                .catch((error) => {
                    console.error(error);
                    this.$buefy.dialog.alert({
                        title: 'Error',
                        message: 'Error inesperado, por favor intente esta acción mas tarde.',
                        type: 'is-danger',
                        hasIcon: true,
                        icon: 'times-circle',
                        iconPack: 'fas',
                        ariaRole: 'alertdialog',
                        ariaModal: true
                    });
                })
                .finally(() => {
                    this.isRequestingReportStatus = false;
                });
        },
        createItemizedReport() {
            this.isLoading = true;
            this.$api.reports
                .createItemizedUnifiedReport({
                    initial_date: this.itemizedReport.range[0],
                    ending_date: this.itemizedReport.range[1]
                })
                .then((res) => {
                    const { token } = res;
                    this.$buefy.dialog.alert({
                        title: 'Solicitud Creada',
                        message: `Se ha generado la solicitud de creación de su reporte, este proceso puede tardar unos minutos. <b>Recuerde guardar su token</b> para consultar el estado del reporte:
                        <br><br><div class="token-container"><div class="input-group"><input type="text" class="token-input" value="${token}" readonly></input><small class="input-helper">⚠️<span class="is-italic ml-1">Una vez cerrado este mensaje, no se podrá recuperar este token.</span></small></div></div>
                        `,
                        type: 'is-info',
                        ariaRole: 'alertdialog',
                        ariaModal: true,
                        confirmText: 'Entendido',
                        canCancel: false
                    });
                })
                .catch(() => {
                    this.$buefy.dialog.alert({
                        title: 'Error',
                        message: 'No se pudo generar su solicitud de reporte, por favor intente nuevamente.',
                        type: 'is-danger',
                        hasIcon: true,
                        icon: 'times-circle',
                        iconPack: 'fas',
                        ariaRole: 'alertdialog',
                        ariaModal: true
                    });
                })
                .finally(() => {
                    this.isLoading = false;
                    this.itemizedReport.range = [];
                });
        },
        searchItemizedReport() {
            this.isLoading = true;
            this.$api.reports
                .searchItemizedUnifiedReport({ token: this.itemizedReport.searchToken })
                .then((res) => {
                    const searchResult = res;
                    this.itemizedReport.itemizedSearchResult = searchResult;
                })
                .catch(() => {
                    this.$buefy.dialog.alert({
                        title: 'Error',
                        message: 'Error inesperado, por favor intente nuevamente',
                        type: 'is-danger',
                        hasIcon: true,
                        icon: 'times-circle',
                        iconPack: 'fas',
                        ariaRole: 'alertdialog',
                        ariaModal: true
                    });
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        getInitialParams() {
            this.isLoading = true;
            this.getGeneralReportStatus();
            this.month = this.currentMonth() - 1;
            this.year = this.currentYear();
            this.isLoading = false;
        },
        formatLocaleDate(date) {
            const options = {
                timeStyle: 'short',
                dateStyle: 'medium'
            };
            return new Date(date).toLocaleString('es-MX', options);
        },
        download(url) {
            const link = document.createElement('a');
            link.href = url;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        currentMonth() {
            return new Date().getMonth() + 1;
        },
        currentYear() {
            return new Date().getFullYear();
        },
        getMonthName(monthNumber) {
            const date = new Date();
            date.setMonth(monthNumber - 1);
            const monthName = date.toLocaleString('es-MX', { month: 'long' });
            return monthName.charAt(0).toLocaleUpperCase() + monthName.slice(1);
        },
        async copyLink() {
            try {
                await navigator.clipboard.writeText(this.reportURL);
                this.$buefy.toast.open({
                    hasIcon: true,
                    message: 'Se copió el Link',
                    type: 'is-success'
                });
            } catch (error) {
                console.error(error);
                this.$buefy.notification.open({
                    message: 'No se pudo copiar el link',
                    type: 'is-danger',
                    hasIcon: true,
                    position: 'is-bottom',
                    duration: 2500
                });
            }
        },
        resetGeneralReportSearch() {
            this.isLoading = true;
            this.reportSearchResult = null;
            this.isLoading = false;
        },
        resetItemizedReportSearch() {
            this.isLoading = true;
            this.itemizedReport.searchToken = null;
            this.itemizedReport.itemizedSearchResult = null;
            this.isLoading = false;
        }
    },
    computed: {
        yearOptions() {
            return Array.from(
                { length: (this.dataAvailability.end.year - this.dataAvailability.start.year) / 1 + 1 },
                (_, i) => this.dataAvailability.start.year + i * 1
            );
        },
        monthOptions() {
            return Array.from(
                { length: (this.dataAvailability.end.month - this.dataAvailability.start.month) / 1 + 1 },
                (_, i) => this.dataAvailability.start.month + i * 1
            );
        },
        minDate() {
            return new Date('01-01-2021');
        },
        maxDate() {
            return new Date();
        }
    },
    mounted() {
        this.getInitialParams();
    }
};
</script>

<style lang="scss" scoped>
.report-options {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    margin-bottom: 50px;
}

.action-btn {
    align-self: flex-end;
}

.card-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.month-selector {
    display: flex;
    flex-direction: column;

    margin-right: 20px;

    .selector__inputs {
        display: flex;
        align-items: center;
    }
}
.divider {
    border-top: 1px solid black;
}

.box {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
    padding: 40px 26px;
}
</style>
