var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"is-relative"},[(_vm.isSaving)?_c('v-loader'):_vm._e(),_c('h1',{staticClass:"title"},[_vm._v("Crear ticket")]),(_vm.errors && _vm.errors.length)?_c('div',{staticClass:"notification is-danger is-light"},[_c('ul',_vm._l((_vm.errors),function(error){return _c('li',[_vm._v(_vm._s(error))])}),0)]):_vm._e(),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveTicket($event)}}},[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-6"},[_c('v-input',{attrs:{"rules":"required","vv-name":"Asunto","name":"subject"},model:{value:(_vm.ticketData.subject),callback:function ($$v) {_vm.$set(_vm.ticketData, "subject", $$v)},expression:"ticketData.subject"}},[_vm._v("Asunto")])],1)]),_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-12"},[_c('h3',{staticClass:"title is-5"},[_vm._v("Datos de contacto")])]),_c('div',{staticClass:"column is-6"},[_c('div',{staticClass:"field"},[_c('label',{staticClass:"label"},[_vm._v("Cliente")]),_c('b-autocomplete',{attrs:{"data":_vm.customers.data,"placeholder":"Buscar...","field":"full_name","loading":_vm.customers.isLoading,"icon":"search","clearable":""},on:{"typing":_vm.fetchCustomers,"select":_vm.selectCustomer},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var option = ref.option;
return [_c('div',{staticClass:"media"},[_c('div',{staticClass:"media-content"},[_c('p',[_vm._v(_vm._s(option.full_name))]),_c('small',[_vm._v(_vm._s(option.email))]),_c('br')])])]}},{key:"empty",fn:function(){return [_vm._v("Sin resultados")]},proxy:true}],null,true),model:{value:(_vm.customers.selected.full_name),callback:function ($$v) {_vm.$set(_vm.customers.selected, "full_name", $$v)},expression:"customers.selected.full_name"}}),_c('p',{staticClass:"help"},[_vm._v("Opcional")])],1)]),_c('div',{staticClass:"column is-6"}),_c('div',{staticClass:"column is-6"},[_c('v-input',{attrs:{"rules":"required|email","vv-name":"Correo electrónico","type":"email","name":"email"},model:{value:(_vm.ticketData.email),callback:function ($$v) {_vm.$set(_vm.ticketData, "email", $$v)},expression:"ticketData.email"}},[_vm._v("Correo electrónico")])],1),_c('div',{staticClass:"column is-6"},[_c('v-input',{attrs:{"rules":"required","vv-name":"Teléfono","type":"phone","name":"email"},model:{value:(_vm.ticketData.phone),callback:function ($$v) {_vm.$set(_vm.ticketData, "phone", $$v)},expression:"ticketData.phone"}},[_vm._v("Teléfono")])],1),_c('div',{staticClass:"column is-12 is-paddingless"},[_c('hr')]),_c('div',{staticClass:"column is-6"},[_c('div',{staticClass:"field"},[_c('label',{staticClass:"label"},[_vm._v("Asignar a")]),_c('b-autocomplete',{attrs:{"data":_vm.users.data,"placeholder":"Buscar...","field":"full_name","loading":_vm.users.isLoading,"icon":"search","clearable":""},on:{"typing":_vm.fetchUsers,"select":_vm.assignToUser},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var option = ref.option;
return [_c('div',{staticClass:"media"},[_c('div',{staticClass:"media-content"},[_c('p',[_vm._v(_vm._s(option.full_name))]),_c('small',[_vm._v(_vm._s(option.email))]),_c('br')])])]}},{key:"empty",fn:function(){return [_vm._v("Sin resultados")]},proxy:true}],null,true),model:{value:(_vm.users.selected.full_name),callback:function ($$v) {_vm.$set(_vm.users.selected, "full_name", $$v)},expression:"users.selected.full_name"}})],1)]),_c('div',{staticClass:"column is-6"}),_c('div',{staticClass:"column is-6"},[_c('v-input',{attrs:{"rules":"required","vv-name":"Comentario","name":"note","type":"textarea"},model:{value:(_vm.ticketData.note),callback:function ($$v) {_vm.$set(_vm.ticketData, "note", $$v)},expression:"ticketData.note"}},[_vm._v("Comentario")])],1),_c('div',{staticClass:"column is-12"},[_c('button',{staticClass:"button is-primary",attrs:{"type":"submit","disabled":invalid || !_vm.ticketData.assigned_to}},[_vm._v("Crear")])])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }