<template>
  <div>
    <h1 class="title">{{ tableTitle }}</h1>
    <div class="columns is-multiline">
      <div class="column is-12">
        <v-loader v-if="isLoading"/>
        <b-table
            paginated
            backend-pagination
            backend-filtering
            :total="pagination.total"
            :per-page="pagination.per_page"
            @page-change="onPageChange"
            :data="orders">
          <b-table-column field="id" label="ID" width="40" numeric v-slot="{row}">
            {{ row.id }}
          </b-table-column>
          <b-table-column field="status" label="Estado" v-slot="{row}">
            <span class="tag is-light"
                  :class="{
                    'is-success': row.status === 'approved',
                    'is-warning': row.status === 'pending',
                    'is-danger': row.status === 'rejected'}">
              {{ row.status | parseOrderStatus }}
            </span>
          </b-table-column>
          <b-table-column field="customer" label="Cliente" v-slot="{row}">
            <p>{{ row.customer.full_name }}</p>
            <small>{{ row.customer.email }}</small>
          </b-table-column>
          <b-table-column field="total" label="Total" numeric v-slot="{row}">
            {{ row.total | formatCurrency }}
          </b-table-column>
          <b-table-column field="created_at" label="Fecha" v-slot="{row}">
            {{ row.created_at | formatDate }}
          </b-table-column>
          <b-table-column v-slot="{row}">
            <router-link class="button is-info is-light is-small"
                         :to="{ name: 'order_detail', params: { id: row.id } }">Ver detalles</router-link>
          </b-table-column>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'OrdersList',
  props: {
    ownOrdersOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLoading: false,
      pagination: {
        page: 1,
        per_page: 10,
        total: 0
      },
      orders: []
    }
  },
  computed: {
    ...mapState('user', ['profile']),
    tableTitle() {
      return this.ownOrdersOnly ? 'Mis órdenes' : 'Órdenes';
    }
  },
  methods: {
    onPageChange(page) {
      this.pagination.page = page;
      this.loadOrders()
    },
    loadOrders(search = '') {
      this.isLoading = true;

      const params = {
        page: this.pagination.page,
        perPage: this.pagination.per_page,
        q: search
      }

      if (this.ownOrdersOnly) {
        params.created_by = this.profile.id;
      }

      this.$api.orders.getOrders(params)
      .then(res => {
        const { data, ...pagination } = res;
        this.orders = data;
        this.pagination = pagination
      })
      .finally(() => {
        this.isLoading = false;
      })
    }
  },
  mounted() {
    this.loadOrders()
  }
}
</script>
