<template>
    <div>
        <v-loader v-if="isLoading" />
        <h1 class="title">Reporte de transacciones</h1>
        <div class="columns is-multiline is-vcentered">
            <div class="column is-8">
                <div class="columns is-multiline">
                    <div v-if="params" class="column is-12">
                        <h4 class="has-text-grey mb-4">Ultima Actualización del reporte: {{ latestStripeUpdate }}</h4>
                        <div class="column is-8">
                            <button :disabled="isLoading" @click="getDownloadReport()" class="button is-primary my-auto">
                                Consultar estado del reporte
                            </button>
                        </div>
                        <b-field label="Rango de generacion">
                            <b-datepicker
                                placeholder="Seleccione un rango de fechas para el reporte"
                                v-model="dates"
                                :min-date="minDate"
                                :max-date="maxDate"
                                range
                            >
                            </b-datepicker>
                        </b-field>
                    </div>
                    <div v-if="dates.length > 1" class="column is-8">
                        <b-field label="Tipo de divisa">
                            <b-select v-model="currency" placeholder="Seleccione una divisa">
                                <option value="mxn">Peso Mexicano</option>
                                <option value="usd">Dolar Estadounidense</option>
                            </b-select>
                        </b-field>
                    </div>
                    <div v-if="dates.length > 0 && currency !== null" class="is-12 column">
                        <button @click="generateTask()" :disabled="isLoading" class="button is-primary my-auto">
                            Generar Reporte
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <b-modal
            :active.sync="isStepsModalActive"
            has-modal-card
            trap-focus
            aria-role="dialog"
            aria-label="Dialogo Estado del reporte"
            close-button-aria-label="Cerrar"
            aria-modal
        >
            <div class="card">
                <header class="card-header">
                    <h1 class="card-header-title">Reporte de transacciones</h1>
                </header>
                <div class="card-content">
                    <div class="columns is-centered is-vcentered">
                        <div class="column is-full">
                            <b-steps :has-navigation="false" animated rounded v-model="steps.activeStep">
                                <b-step-item step="1" label="Stripe" :clickable="false">
                                    <div class="columns is-centered is-vcentered is-multiline">
                                        <div class="column is-full">
                                            <div class="content">
                                                <h2 class="title has-text-centered">Solicitando datos a stripe</h2>
                                                En este momento su reporte fue solicitado a Stripe, y esta a la espera de que se
                                                proporcione la información correspondiente para su procesamiento. Tiempo estimado
                                                para completar <b>1-3 Minutos</b>
                                            </div>
                                        </div>
                                    </div>
                                </b-step-item>

                                <b-step-item step="2" label="Procesando" :clickable="false">
                                    <div class="columns is-centered is-vcentered is-multiline">
                                        <div class="column is-full">
                                            <div class="content">
                                                <h2 class="title has-text-centered">Procesando su reporte</h2>
                                                En este momento su reporte se encuentra en procesamiento con la información
                                                suministrada por Stripe. Tiempo estimado para completar
                                                <b>5 - 10 Minutos</b>
                                            </div>
                                        </div>
                                        <div class="column is-full">
                                            <div class="content">
                                                <b-notification :closable="false" type="is-info" has-icon>
                                                    Los tiempos estimados en este paso son variables y directamente proporcionales
                                                    a el rango de tiempo seleccionado para la generación del reporte y la cantidad
                                                    de transacciones en ese rango.
                                                    <strong>
                                                        Recuerde seleccionar rangos mas cortos para un tiempo de procesamiento mas
                                                        corto
                                                    </strong>
                                                </b-notification>
                                            </div>
                                        </div>
                                    </div>
                                </b-step-item>

                                <b-step-item step="3" label="Listo" :clickable="download_link !== null && download_link !== ''">
                                    <div class="columns is-centered is-vcentered is-multiline">
                                        <div class="column is-full">
                                            <div class="content">
                                                <h2 class="title has-text-centered">Listo para descargar</h2>
                                                Su reporte ya se encuentra listo para descargar. puede hacerlo con el botón en la
                                                parte inferior, o copiando el vinculo de descarga
                                            </div>
                                        </div>
                                        <div class="column is-full">
                                            <b-field position="is-centered">
                                                <b-input
                                                    v-model="download_link"
                                                    readonly
                                                    placeholder="Search..."
                                                    type="text"
                                                    ref="download_text"
                                                    rounded
                                                    expanded
                                                >
                                                </b-input>
                                                <p class="control">
                                                    <b-button
                                                        rounded
                                                        icon-left="clipboard"
                                                        icon-pack="fas"
                                                        label="Copiar"
                                                        type="is-primary"
                                                        @click.prevent="copyLink()"
                                                    />
                                                </p>
                                            </b-field>
                                        </div>
                                        <div class="column is-full has-text-centered">
                                            <div class="content">
                                                <b-button
                                                    type="is-primary"
                                                    outlined
                                                    icon-right="file-download"
                                                    icon-pack="fas"
                                                    @click.prevent="download(download_link)"
                                                >
                                                    Descargar
                                                </b-button>
                                            </div>
                                        </div>
                                    </div>
                                </b-step-item>
                            </b-steps>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
export default {
    name: 'ItemizedPayoutsView',
    data() {
        return {
            isLoading: false,
            params: null,
            dates: [],
            currency: null,
            isStepsModalActive: false,
            steps: {
                activeStep: 0
            },
            download_link: ''
        };
    },
    methods: {
        getInitialParams() {
            this.isLoading = true;
            this.$api.reports
                .getPayoutItemizedParams()
                .then((res) => {
                    this.params = res.data;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        download(url) {
            const anchorElement = document.createElement('a');
            anchorElement.href = url;
            anchorElement.download = 'nameOfDownload';

            document.body.appendChild(anchorElement);
            anchorElement.click();

            document.body.removeChild(anchorElement);
            window.URL.revokeObjectURL(href);
        },
        generateTask() {
            this.isLoading = true;
            this.$api.reports
                .generateItemizedPayouts({
                    currency: this.currency,
                    initial_date: this.dates[0],
                    ending_date: this.dates[1]
                })
                .then((res) => {
                    const { token } = res;
                    this.$buefy.dialog.alert({
                        title: 'Solicitud Creada',
                        message: `Se genero correctamente su solicitud de reporte, este proceso puede tardar unos minutos.\n
               Recuerde que para poder consultar el estado de su reporte o descargarlo una vez este listo,
               debe conservar el siguiente token: \n <b>${token}</b>`,
                        type: 'is-info',
                        hasIcon: true,
                        icon: 'info-circle',
                        iconPack: 'fas',
                        ariaRole: 'alertdialog',
                        ariaModal: true,
                        confirmText: 'Entendido',
                        canCancel: false
                    });
                })
                .catch(() => {
                    this.$buefy.dialog.alert({
                        title: 'Error en Generación',
                        message: 'No se pudo generar su solicitud de reporte, por favor trate nuevamente',
                        type: 'is-danger',
                        hasIcon: true,
                        icon: 'times-circle',
                        iconPack: 'fas',
                        ariaRole: 'alertdialog',
                        ariaModal: true
                    });
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        formatLocaleDate(date) {
            const options = {
                timeStyle: 'short',
                dateStyle: 'medium'
            };
            return new Date(date).toLocaleString('es-MX', options);
        },
        getDownloadReport() {
            this.isLoading = true;
            this.$buefy.dialog.prompt({
                title: 'ver Reporte',
                message: `Ingrese su token, para verificar el estado del reporte.`,
                inputAttrs: {
                    placeholder: 'Token de Reporte'
                },
                trapFocus: true,
                onConfirm: (value) => {
                    this.$api.reports.downloads
                        .getItemizedPayouts({ token: value })

                        .then((res) => {
                            const { status, report_link } = res;
                            switch (status) {
                                case 'stripe_pending':
                                    this.steps.activeStep = 0;
                                    this.isStepsModalActive = true;
                                    break;

                                case 'processing':
                                    this.steps.activeStep = 1;
                                    this.isStepsModalActive = true;
                                    break;

                                case 'ready':
                                    this.steps.activeStep = 2;
                                    this.download_link = report_link;
                                    this.isStepsModalActive = true;
                                    break;

                                case 'unknown':
                                    this.$buefy.dialog.alert({
                                        title: 'Reporte no disponible',
                                        message:
                                            'Puede que su reporte no exista o no este disponible para procesamiento, por favor genere uno nuevo',
                                        type: 'is-info',
                                        hasIcon: true,
                                        icon: 'exclamation-triangle',
                                        iconPack: 'fas',
                                        ariaRole: 'alertdialog',
                                        ariaModal: true
                                    });
                                    break;

                                default:
                                    this.steps.activeStep = 1;
                                    this.isStepsModalActive = true;
                                    break;
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                            this.$buefy.dialog.alert({
                                title: 'Error en el Reporte',
                                message:
                                    'Puede que su reporte no se encuentre listo o haya ocurrido un error al generarlo, por favor tratelo nuevamente mas tarde o genere uno nuevo',
                                type: 'is-danger',
                                hasIcon: true,
                                icon: 'times-circle',
                                iconPack: 'fas',
                                ariaRole: 'alertdialog',
                                ariaModal: true
                            });
                        })
                        .finally(() => {
                            this.isLoading = false;
                        });
                }
            });
        },
        async copyLink() {
            try {
                await navigator.clipboard.writeText(this.download_link);
                this.$buefy.notification.open({
                    message: 'Se copio el link correctamente',
                    type: 'is-success',
                    hasIcon: true,
                    position: 'is-bottom',
                    duration: 2500
                });
            } catch (error) {
                console.error(error);
                this.$buefy.notification.open({
                    message: 'No se pudo copiar el link',
                    type: 'is-danger',
                    hasIcon: true,
                    position: 'is-bottom',
                    duration: 2500
                });
            }
        }
    },
    mounted() {
        this.getInitialParams();
    },
    computed: {
        latestStripeUpdate() {
            return this.params ? this.formatLocaleDate(this.params['last_stripe_update']) : 'No Disponible';
        },
        minDate() {
            return this.params ? new Date(this.params['min_date']) : null;
        },
        maxDate() {
            return this.params ? new Date(this.params['max_date']) : null;
        }
    }
};
</script>
