<template>
  <div>
    <h1 class="title">{{ mainTitle }}</h1>
    <div class="columns is-multiline">
<!--      <div class="column is-6">-->
<!--        <search-block @search="loadTickets" @clear="loadTickets"/>-->
<!--      </div>-->
      <div class="column is-12">
        <v-loader v-if="isLoading"/>
        <b-table
            paginated
            backend-pagination
            backend-filtering
            :total="pagination.total"
            :per-page="pagination.per_page"
            @page-change="onPageChange"
            :data="tickets">
          <b-table-column field="id" label="ID" width="40" numeric v-slot="{row}">
            {{ row.id }}
          </b-table-column>
          <b-table-column field="subject" label="Asunto" v-slot="{row}">
            {{ row.subject }}
          </b-table-column>
<!--          <b-table-column field="author" label="Creado por" v-slot="{row}">-->
<!--            {{ row.author.full_name }}-->
<!--          </b-table-column>-->
          <b-table-column field="assignee" label="Asignado a" v-slot="{row}">
            {{ row.assignee.full_name }}
          </b-table-column>
          <b-table-column field="notes_count" label="Comentarios" v-slot="{row}">
            <span class="tag is-primary">{{ row.notes_count }}</span>
          </b-table-column>
          <b-table-column field="created_at" label="Fecha" v-slot="{row}">
            {{ row.created_at | formatDate }}
          </b-table-column>
          <b-table-column label="Acciones" v-slot="{row}">
            <div class="buttons has-addons">
              <router-link class="button is-small is-info is-light" :to="{ name: 'ticket_details', params: { id: row.id } }">Ver detalles</router-link>
            </div>
          </b-table-column>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState, mapGetters } from 'vuex';

export default {
  name: 'OpenTicketsList',
  data() {
    return {
      isLoading: false,
      pagination: {
        page: 1,
        per_page: 10,
        total: 0
      },
      tickets: []
    }
  },
  computed: {
    ...mapState('user', ['profile']),
    ...mapGetters('user', ['isAdmin']),
    mainTitle() {
      return this.isAdmin ? 'Tickets abiertos' : 'Mis tickets'
    }
  },
  methods: {
    onPageChange(page) {
      this.pagination.page = page;
      this.loadTickets()
    },
    loadTickets(search = '') {
      this.isLoading = true;

      const params = {
        page: this.pagination.page,
        perPage: this.pagination.per_page,
        q: search
      }

      if (!this.isAdmin) {
        params.assigned_to = this.profile.id;
      }

      this.$api.tickets.getTickets(params).then(res => {
        const { data, ...pagination } = res;
        this.tickets = data;
        this.pagination = pagination
      }).finally(() => {
        this.isLoading = false;
      })
    }
  },
  mounted() {
    this.loadTickets();
  }
}
</script>
