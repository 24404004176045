var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-6"},[(_vm.customer.data)?_c('h1',{staticClass:"title is-2"},[_vm._v(_vm._s(_vm.customer.data.full_name))]):_c('b-skeleton',{attrs:{"size":"is-large"}})],1),_c('div',{staticClass:"column is-6"}),_c('div',{staticClass:"column is-4"},[_c('span',{staticClass:"label"},[_vm._v("Correo electrónico")]),(_vm.customer.data)?_c('a',{attrs:{"href":("mailto:" + (_vm.customer.data.email))}},[_vm._v(_vm._s(_vm.customer.data.email))]):_c('b-skeleton')],1),_c('div',{staticClass:"column is-4"},[_c('span',{staticClass:"label"},[_vm._v("Teléfono")]),(_vm.customer.data)?_c('p',[_vm._v(_vm._s(_vm.customer.data.phone))]):_c('b-skeleton')],1)]),_c('div',{staticClass:"columns is-multiline"},[_vm._m(0),_c('div',{staticClass:"column is-12"},[_c('b-table',{attrs:{"loading":_vm.subscriptions.isLoading,"data":_vm.subscriptions.list}},[_c('b-table-column',{attrs:{"field":"id","label":"ID","width":"40","numeric":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.id)+" ")]}}])}),_c('b-table-column',{attrs:{"field":"name","label":"Suscripción"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.name)+" ")]}}])}),_c('b-table-column',{attrs:{"field":"status","label":"Estado"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"tag is-light",class:{
            'is-success': row.stripe_status === 'active',
            'is-warning': row.stripe_status === 'trialing',
            'is-danger': row.stripe_status === 'canceled' ||
                          row.stripe_status === 'past_due' ||
                          row.stripe_status === 'unpaid' ||
                          row.stripe_status === 'incomplete' ||
                          row.stripe_status === 'incomplete_expired'
          }},[_vm._v(" "+_vm._s(_vm._f("parseStripeSubscriptionStatus")(row.stripe_status))+" ")])]}}])}),_c('b-table-column',{attrs:{"field":"ends_at","label":"Expiración"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(row.ends_at))+" ")]}}])}),_c('b-table-column',{attrs:{"label":"Acciones"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('div',{staticClass:"buttons has-addons"},[(row.stripe_status === 'active')?_c('button',{staticClass:"button is-small is-danger is-light",attrs:{"type":"button"},on:{"click":function($event){return _vm.promptCancel(row.id)}}},[_vm._v("Cancelar")]):_vm._e()])]}}])}),(!_vm.subscriptions.isLoading)?_c('div',{staticClass:"message is-dark",attrs:{"slot":"empty"},slot:"empty"},[_c('div',{staticClass:"message-body"},[_c('p',[_vm._v("El cliente no tiene suscripciones")])])]):_vm._e()],1)],1)]),_c('div',{staticClass:"columns is-multiline"},[_vm._m(1),_c('div',{staticClass:"column is-12"},[_c('b-table',{attrs:{"loading":_vm.orders.isLoading,"data":_vm.orders.list}},[_c('b-table-column',{attrs:{"field":"id","label":"ID","width":"40","numeric":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_vm._v(" "+_vm._s(row.id)+" ")]}}])}),_c('b-table-column',{attrs:{"field":"status","label":"Estado"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('span',{staticClass:"tag is-light",class:{
                  'is-success': row.status === 'approved',
                  'is-warning': row.status === 'pending',
                  'is-danger': row.status === 'rejected'}},[_vm._v(" "+_vm._s(_vm._f("parseOrderStatus")(row.status))+" ")])]}}])}),_c('b-table-column',{attrs:{"field":"total","label":"Total","numeric":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("formatCurrency")(row.total))+" ")]}}])}),_c('b-table-column',{attrs:{"field":"created_at","label":"Fecha"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(row.created_at))+" ")]}}])}),_c('b-table-column',{attrs:{"label":"Acciones"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var row = ref.row;
return [_c('router-link',{staticClass:"button is-info is-light is-small",attrs:{"to":{ name: 'order_detail', params: { id: row.id } }}},[_vm._v("Ver detalles")])]}}])})],1)],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column is-12"},[_c('h2',{staticClass:"title is-4"},[_vm._v("Suscripciones")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column is-12"},[_c('h2',{staticClass:"title is-4"},[_vm._v("Órdenes")])])}]

export { render, staticRenderFns }