<template>
  <div class="columns is-multiline">
    <div class="column is-12">
      <h4 class="title is-4">Reportes</h4>
    </div>
    <div class="column is-6">
      <h5 class="title is-6">Reporte de ventas</h5>
      <form :action="`${baseUrl}/reports/downloads/sales`">
        <div class="field has-addons">
          <p class="control">
            <span class="select">
              <select name="month" id="month">
                <option value="1">Enero</option>
                <option value="2">Febrero</option>
                <option value="3">Marzo</option>
                <option value="4">Abril</option>
                <option value="5">Mayo</option>
                <option value="6">Junio</option>
                <option value="7">Julio</option>
                <option value="8">Agosto</option>
                <option value="9">Septiembre</option>
                <option value="10">Octubre</option>
                <option value="11">Noviembre</option>
                <option value="12">Diciembre</option>
              </select>
            </span>
          </p>
          <p class="control">
            <span class="select">
              <select name="year" id="year">
                <option :value="year" v-for="year in yearOptions">{{year}}</option>
              </select>
            </span>
          </p>
          <p class="control">
            <button type="submit" class="button is-primary">Descargar</button>
          </p>
        </div>
      </form>
    </div>
    <div class="column is-12">
      <hr>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DownloadableReports',
  computed: {
    currentMonth() {
      return new Date().getMonth() + 1;
    },
    yearOptions() {
      const now = new Date();
      const minYear = 2021;

      let years = [];

      for (let year = minYear; year < now.getFullYear() + 1; year++) {
        years.push(year)
      }

      return years;
    },
    baseUrl() {
      return process.env.VUE_APP_API_BASE;
    }
  }
}
</script>
