var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"title"},[_vm._v("Usuarios")]),_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-6"},[_c('search-block',{on:{"search":_vm.loadUsers,"clear":_vm.loadUsers}})],1),_c('div',{staticClass:"column is-12"},[(_vm.isLoading)?_c('v-loader'):_vm._e(),_c('b-table',{attrs:{"paginated":"","backend-pagination":"","backend-filtering":"","total":_vm.pagination.total,"per-page":_vm.pagination.per_page,"data":_vm.users},on:{"page-change":_vm.onPageChange}},[_c('b-table-column',{attrs:{"field":"id","label":"ID","width":"40","numeric":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.id)+" ")]}}])}),_c('b-table-column',{attrs:{"field":"full_name","label":"Nombre"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.full_name)+" ")]}}])}),_c('b-table-column',{attrs:{"field":"email","label":"E-mail"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.email)+" ")]}}])}),_c('b-table-column',{attrs:{"label":"Órdenes"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('router-link',{staticClass:"button is-small is-primary",attrs:{"to":"","disabled":!row.orders_count}},[_vm._v(" "+_vm._s(row.orders_count)+" ")])]}}])}),_c('b-table-column',{attrs:{"label":"Tipo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"tags"},[_c('span',{staticClass:"tag is-light",class:{'is-success': _vm.isAdmin(row), 'is-warning': !_vm.isAdmin(row)}},[_vm._v(" "+_vm._s(_vm._f("parseUserRoles")(row.roles))+" ")]),(!row.is_active)?_c('span',{staticClass:"tag is-danger is-light"},[_vm._v(" Desactivado ")]):_vm._e()])]}}])}),_c('b-table-column',{attrs:{"label":"Acciones"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.is_active)?_c('div',{staticClass:"buttons has-addons"},[_c('router-link',{staticClass:"button is-small is-primary",attrs:{"to":{ name: 'edit_user', params: { id: row.id } }}},[_vm._v("Editar")]),_c('button',{staticClass:"button is-small is-danger",on:{"click":function($event){return _vm.promptDisable(row)}}},[_vm._v("Desactivar")])],1):_c('button',{staticClass:"button is-small is-warning",on:{"click":function($event){return _vm.promptEnable(row)}}},[_vm._v("Reactivar")])]}}])})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }