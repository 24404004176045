<template>
  <div class="columns is-centered is-multiline is-fullwidth">
    <div class="column is-6">
      <figure>
        <img src="../../assets/condenast_logo.png" alt="">
      </figure>
    </div>
    <div class="column is-9">
      <div class="card">
        <div class="card-header">
          <p class="card-header-title">Ingresar</p>
        </div>
        <div class="card-content">
          <v-loader v-if="isLoading"/>
          <form @submit.prevent="onFormSubmit">
            <ValidationObserver v-slot="{ valid, invalid }">
              <div class="columns is-multiline">
                <div class="column is-12">
                  <v-input v-model="email" rules="required" type="email" vv-name="Correo electrónico"
                           placeholder="Correo electrónico">Correo electrónico</v-input>
                </div>
                <div class="column is-12">
                  <v-input v-model="password" rules="required" type="password" vv-name="Contraseña"
                           placeholder="Contraseña">Contraseña</v-input>
                </div>
                <div class="column is-12">
                  <div class="message is-danger" v-if="failed">
                    <div class="message-body">
                      <p>Usuario y/o contraseña incorrectos</p>
                    </div>
                  </div>
                </div>
                <div class="column is-12">
                  <div class="field">
                    <router-link to="/forgot-password">Recuperar contraseña</router-link>
                  </div>
                </div>
                <div class="column is-12">
                  <div class="field">
                    <button type="submit" class="button is-primary" :disabled="invalid">Ingresar</button>
                  </div>
                </div>
              </div>
            </ValidationObserver>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';

export default {
  name: 'Login',
  data() {
    return {
      email: '',
      password: '',
      failed: false
    }
  },
  computed: {
    ...mapState('user', ['isLoading']),
    ...mapGetters('user', ['isAuthenticated'])
  },
  methods: {
    ...mapActions('user', ['tryLogin']),
    onFormSubmit() {
      this.tryLogin({
        email: this.email,
        password: this.password
      }).then(() => {
        this.$router.replace('/');
      }).catch(e => {
        this.failed = true;
      })
    }
  },
  mounted() {
    if (this.isAuthenticated) this.$router.replace('/')
  }
}
</script>
