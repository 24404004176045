<template>
  <chart-wrapper>
    <h4 class="title is-6">Órdenes por país</h4>
    <div class="is-relative">
      <v-loader v-if="isLoading"/>
      <pie-chart v-else-if="chartData" :chartdata="chartData"/>
      <p v-else class="has-text-centered">No hay datos disponibles</p>
    </div>
  </chart-wrapper>
</template>

<script>
import { getRandomColors } from '@/utils/charts-helpers';

export default {
  name: 'OrdersByCountry',
  data() {
    return {
      isLoading: false,
      chartData: null,
    }
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      this.$api.reports.getOrdersByCountry()
          .then(result => {
            let data = [];
            let labels = [];

            for (let item of result) {
              data.push(item.count);
              labels.push(item.name);
            }

            if (data.length) {
              this.chartData = {
                datasets: [{
                  data,
                  backgroundColor: getRandomColors(labels.length)
                }],
                labels
              };
            }
          }).finally(() => {
        this.isLoading = false;
      })
    }
  },
  mounted() {
    this.fetchData();
  }
}
</script>
