<template>
  <chart-wrapper>
    <h4 class="title is-6">Órdenes por estado</h4>
    <div class="is-relative">
      <v-loader v-if="isLoading"/>
      <pie-chart v-else-if="chartData" :chartdata="chartData"/>
      <p v-else class="has-text-centered">No hay datos disponibles</p>
<!--      <hr>-->
<!--      <div class="has-text-centered">-->
<!--        <router-link to="/orders" class="button is-primary">Ver órdenes</router-link>-->
<!--      </div>-->
    </div>
  </chart-wrapper>
</template>

<script>
import { colorsByStatus } from '@/utils/charts-helpers';

export default {
  name: 'OrdersByStatus',
  data() {
    return {
      isLoading: false,
      chartData: null,
    }
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      this.$api.reports.getOrdersCount()
          .then(result => {
            let data = [];
            let backgroundColor = [];
            let labels = [];

            for (let status in result) {
              if (result.hasOwnProperty(status)) {
                data.push(result[status]);
                labels.push(
                    this.$options.filters.parseOrderStatus(status)
                )

                let color = colorsByStatus.danger;

                if (status === 'pending') {
                  color = colorsByStatus.warning;
                } else if (status === 'approved') {
                  color = colorsByStatus.success;
                }

                backgroundColor.push(color)
              }
            }

            if (data.length) {
              this.chartData = {
                datasets: [{
                  data,
                  backgroundColor
                }],
                labels
              };
            }
          }).finally(() => {
        this.isLoading = false;
      })
    }
  },
  mounted() {
    this.fetchData();
  }
}
</script>
