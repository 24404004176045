<template>
  <div class="columns is-multiline">
    <div class="column is-12">
      <h1 class="title">
        <span>Ticket <b>#{{ ticketId }}</b></span><br>
        <span class="tag"
              :class="{'is-warning': isOpen, 'is-success': !isOpen}"
              v-if="ticket">{{ ticket.status | parseTicketStatus }}</span>
      </h1>
    </div>
    <div class="column is-6">
      <h3 class="title is-spaced is-5">Asunto</h3>
      <div>
        <b-skeleton size="is-large" v-if="isLoadingTicket"/>
        <h3 class="subtitle" v-else-if="ticket">{{ ticket.subject }}</h3>
      </div>
    </div>
    <div class="column is-6">
      <h3 class="title is-5">Datos de contacto</h3>
      <div>
        <b-skeleton v-if="isLoadingTicket"/>
        <p v-else-if="ticket">E-mail: <a :href="`mailto:${ticket.email}`" target="_blank">{{ ticket.email }}</a></p>
      </div>
      <div>
        <b-skeleton v-if="isLoadingTicket"/>
        <p v-else-if="ticket">Teléfono: {{ ticket.phone }}</p>
      </div>
    </div>
    <div class="column is-4">
      <p><b>Creado por</b></p>
      <b-skeleton v-if="isLoadingTicket"/>
      <p v-else-if="ticket">{{ ticket.author.full_name }}</p>
    </div>
    <div class="column is-12 is-paddingless">
      <hr>
    </div>
    <div class="column is-6">
      <div class="field">
        <label for="assignee" class="label">Asignar a</label>
        <b-skeleton size="is-large" v-if="isLoadingTicket"/>
        <b-autocomplete
            v-else-if="ticket"
            :data="users.data"
            placeholder="Buscar..."
            v-model="ticket.assignee.full_name"
            field="full_name"
            :loading="users.isLoading"
            @typing="fetchUsers"
            icon="search"
            :disabled="!isOpen"
            @select="assignToUser">

          <template slot-scope="{option}">
            <div class="media">
              <div class="media-content">
                <p>{{ option.full_name }}</p>
                <small>{{ option.email }}</small><br>
              </div>
            </div>
          </template>
          <template #empty>Sin resultados</template>
        </b-autocomplete>
      </div>
    </div>
    <div class="column is-12 is-paddingless">
      <hr>
    </div>
    <div class="column is-12">
      <div class="columns is-multiline is-centered">
        <div class="column is-10">
          <h4 class="title is-5">Comentarios</h4>
        </div>
        <template v-if="showNotesSkeleton">
          <div class="column is-10">
            <article class="message">
              <div class="message-header">
                <b-skeleton/>
              </div>
              <div class="message-body">
                <b-skeleton/>
                <b-skeleton/>
                <b-skeleton/>
              </div>
            </article>
          </div>
        </template>
        <template v-else>
          <div class="column is-10" v-for="(note, index) in notes.data" :key="note.id">
            <article class="message is-primary"
                     :class="{'is-info': index === 0, 'is-success': index === notes.data.length - 1 && !isOpen}">
              <div class="message-header">
                <p>{{ note.author.full_name }}, {{ note.created_at | formatDate(true) }}</p>
              </div>
              <div class="message-body">
                <div class="content" v-html="note.note"></div>
              </div>
            </article>
          </div>
          <div class="column is-10" v-if="ticket && isOpen">
            <h5 class="title is-5">Agregar comentario</h5>
            <ValidationObserver v-slot="{ valid, invalid }">
              <form class="columns is-multiline is-relative" @submit.prevent="addComment">
                <v-loader v-if="notes.isSaving"/>
                <div class="column is-12">
                  <v-input type="textarea" vv-name="Comentario" v-model="notes.newNote"
                           name="note" rules="required" ref="noteInput">Comentario</v-input>
                </div>
                <div class="column is-6">
                  <b-switch v-model="shouldCloseTicket" type="is-success" left-label>
                    Cerrar ticket
                  </b-switch>
                </div>
                <div class="column is-6 has-text-right">
                  <button type="submit" class="button is-primary" :disabled="invalid || notes.isSaving">Comentar</button>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </template>
      </div>
    </div>
<!--    <div class="column is-4">-->
<!--      <h4 class="title is-5">Eventos</h4>-->
<!--    </div>-->
  </div>
</template>

<script>
import { debounce } from 'lodash';

export default {
  name: 'TicketDetails',
  data() {
    return {
      isLoadingTicket: false,
      shouldCloseTicket: false,
      errors: null,
      ticket: null,
      users: {
        isLoading: false,
        data: [],
        assignee: null,
      },
      notes: {
        isLoading: false,
        isSaving: false,
        data: [],
        newNote: ''
      }
    }
  },
  computed: {
    isOpen() {
      return this.ticket && this.ticket.status === 'open';
    },
    ticketId() {
      return this.$route.params.id;
    },
    showNotesSkeleton() {
      const { data, isLoading } = this.notes;
      return !data.length && isLoading;
    }
  },
  methods: {
    assignToUser(user) {
      if (!user) return;

      this.$api.tickets.updateTicket(this.ticketId, {
        assigned_to: user.id
      }).then(() => {
        this.$buefy.toast.open({
          message: `Asignado a <b>${user.full_name}</b>`,
          type: 'is-success'
        })
      })
    },
    fetchUsers: debounce(function (value) {
      if (!value.length) {
        this.users.data = []
        return
      }

      this.users.isLoading = true;

      this.$api.users.getUsers({
        q: value,
        role: 'agent'
      }).then(result => {
        this.users.data = result.data
      }).finally(() => {
        this.users.isLoading = false;
      })

    }, 500),
    fetchTicket() {
      this.isLoadingTicket = true;
      return this.$api.tickets.getTicket(this.ticketId)
      .then(result => {
        this.ticket = result;
      }).finally(() => {
        this.isLoadingTicket = false;
      })
    },
    closeTicket() {
      this.$api.tickets.updateTicket(this.ticketId, {
        status: 'closed'
      }).then(() => {
        this.$buefy.toast.open({
          message: `Ticket cerrado`,
          type: 'is-success'
        })

        this.fetchTicket();
      })
    },
    addComment() {
      this.notes.isSaving = true;
      this.$api.tickets.addTicketNote(this.ticketId, {
        note: this.notes.newNote
      }).then(() => {
        this.notes.newNote = '';
        this.$refs.noteInput.resetValidation();
        this.fetchTicketNotes().finally(() => {
          this.notes.isSaving = false;
        })
      });

      if (this.shouldCloseTicket) {
        this.closeTicket()
      }
    },
    fetchTicketNotes() {
      this.notes.isLoading = true;
      return this.$api.tickets.getTicketNotes(this.ticketId)
      .then(result => {
        this.notes.data = result;
      }).finally(() => {
        this.notes.isLoading = false;
      })
    }
  },
  mounted() {
    this.fetchTicket();
    this.fetchTicketNotes()
  }
}
</script>
