<template>
  <chart-wrapper>
    <h4 class="title is-6">Órdenes por agente</h4>
    <div class="is-relative">
      <v-loader v-if="isLoading"/>
      <bar-chart v-else-if="chartData" :chartdata="chartData" :options="chartOptions"/>
      <p v-else class="has-text-centered">No hay datos disponibles</p>
<!--      <hr>-->
<!--      <div class="has-text-centered">-->
<!--        <router-link to="/tickets" class="button is-primary">Ver tickets</router-link>-->
<!--      </div>-->
    </div>
  </chart-wrapper>
</template>

<script>
import { colors, translucentColors, getRandomColors } from '@/utils/charts-helpers';

export default {
  name: 'TicketsByStatus',
  data() {
    return {
      isLoading: false,
      chartData: null,
      chartOptions: {
        plugins: {
          legend: {
            position: 'top',
          },
        },
      },
      // chartData: {
      //   datasets: [
      //     {
      //       label: 'Órdenes',
      //       data: [4, 10, 30, 18],
      //       backgroundColor: getRandomColors(4),
      //       hoverBackgroundColor: getRandomColors(4),
      //       borderWidth: 2,
      //       borderRadius: 5
      //     }
      //   ],
      //   labels: ['Agent 1', 'Agent 2', 'Agent 3', 'Agent 4']
      // }
    }
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      this.$api.reports.getOrdersByAgent()
        .then(result => {
          let data = [];

          let labels = [];

          for (let item of result) {
            data.push(item.count);
            labels.push(
                item.created_by.full_name
            )
          }

          if (data.length) {
            const colors = getRandomColors(labels.length);

            this.chartData = {
              datasets: [{
                data,
                label: 'Órdenes',
                borderColor: colors,
                backgroundColor: colors,
                borderWidth: 2,
                borderRadius: 5,
              }],
              labels
            };
          }
        }).finally(() => {
          this.isLoading = false;
        })
    }
  },
  mounted() {
    this.fetchData();
  }
}
</script>
