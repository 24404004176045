<template>
  <div>
    <div class="columns is-multiline">
      <div class="column is-6">
        <h1 class="title is-2" v-if="customer.data">{{ customer.data.full_name }}</h1>
        <b-skeleton size="is-large" v-else/>
      </div>
      <div class="column is-6"></div>
      <div class="column is-4">
        <span class="label">Correo electrónico</span>
        <a :href="`mailto:${customer.data.email}`" v-if="customer.data">{{ customer.data.email }}</a>
        <b-skeleton v-else/>
      </div>
      <div class="column is-4">
        <span class="label">Teléfono</span>
        <p v-if="customer.data">{{ customer.data.phone }}</p>
        <b-skeleton v-else/>
      </div>
    </div>

    <div class="columns is-multiline">
      <div class="column is-12">
        <h2 class="title is-4">Suscripciones</h2>
      </div>
      <div class="column is-12">
        <b-table
              :loading="subscriptions.isLoading"
              :data="subscriptions.list"
          >
            <b-table-column field="id" label="ID" width="40" numeric v-slot="{row}">
              {{ row.id }}
            </b-table-column>
            <b-table-column field="name" label="Suscripción" v-slot="{row}">
              {{ row.name }}
            </b-table-column>
            <b-table-column field="status" label="Estado" v-slot="{row}">
            <span class="tag is-light" :class="{
              'is-success': row.stripe_status === 'active',
              'is-warning': row.stripe_status === 'trialing',
              'is-danger': row.stripe_status === 'canceled' ||
                            row.stripe_status === 'past_due' ||
                            row.stripe_status === 'unpaid' ||
                            row.stripe_status === 'incomplete' ||
                            row.stripe_status === 'incomplete_expired'
            }">
              {{ row.stripe_status | parseStripeSubscriptionStatus }}
            </span>
            </b-table-column>
            <b-table-column field="ends_at" label="Expiración" v-slot="{row}">
              {{ row.ends_at | formatDate}}
            </b-table-column>
            <b-table-column label="Acciones" v-slot="{row}">
              <div class="buttons has-addons">
                <button type="button" class="button is-small is-danger is-light" v-if="row.stripe_status === 'active'" @click="promptCancel(row.id)">Cancelar</button>
              </div>
            </b-table-column>
            <div class="message is-dark" slot="empty" v-if="!subscriptions.isLoading">
              <div class="message-body">
                <p>El cliente no tiene suscripciones</p>
              </div>
            </div>
        </b-table>
      </div>
    </div>
    <div class="columns is-multiline">
      <div class="column is-12">
        <h2 class="title is-4">Órdenes</h2>
      </div>
      <div class="column is-12">
        <b-table
            :loading="orders.isLoading"
            :data="orders.list">
          <b-table-column field="id" label="ID" width="40" numeric v-slot="{row}">
            {{ row.id }}
          </b-table-column>
          <b-table-column field="status" label="Estado" v-slot="{row}">
            <span class="tag is-light"
                  :class="{
                    'is-success': row.status === 'approved',
                    'is-warning': row.status === 'pending',
                    'is-danger': row.status === 'rejected'}">
              {{ row.status | parseOrderStatus }}
            </span>
          </b-table-column>
          <b-table-column field="total" label="Total" numeric v-slot="{row}">
            {{ row.total | formatCurrency }}
          </b-table-column>
          <b-table-column field="created_at" label="Fecha" v-slot="{row}">
            {{ row.created_at | formatDate }}
          </b-table-column>
          <b-table-column label="Acciones" v-slot="{row}">
            <router-link class="button is-info is-light is-small"
                         :to="{ name: 'order_detail', params: { id: row.id } }">Ver detalles</router-link>
          </b-table-column>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomerDetail',
  data() {
    return {
      customer: {
        isLoading: false,
        data: null
      },
      subscriptions: {
        isLoading: false,
        list: []
      },
      orders: {
        isLoading: false,
        list: []
      }
    }
  },
  computed: {
    customerId() {
      return this.$route.params.id;
    },
  },
  methods: {
    fetchCustomer() {
      this.customer.isLoading = true;
      this.$api.customers.getCustomer(this.customerId)
      .then(result => {
        this.customer.data = result;
      }).finally(() => {
        this.customer.isLoading = false;
      })
    },
    fetchCustomerSubscriptions() {
      this.subscriptions.isLoading = true;
      this.$api.customers.getCustomerSubscriptions(this.customerId)
      .then(result => {
        this.subscriptions.list = result;
      }).finally(() => {
        this.subscriptions.isLoading = false;
      })
    },
    fetchCustomerOrders() {
      this.orders.isLoading = true;
      this.$api.customers.getCustomerOrders(this.customerId)
      .then(result => {
        this.orders.list = result;
      }).finally(() => {
        this.orders.isLoading = false;
      })
    },
    promptCancel(id) {
      this.$buefy.dialog.confirm({
                    title: 'Cancelando Subscripcion',
                    message: 'Esta seguro de que desea <b>cancelar</b> esta subscripcion?',
                    confirmText: 'Cancelar Subscripcion',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => this.cancelSubscription(id)
                })
    },
    cancelSubscription(id) {
      this.isLoading = true;
      this.$api.subscriptions
        .cancel(id)
        .then(() => this.$buefy.toast.open('La subscripcion se cancelo satisfactoriamente'))
        .finally(() => {
          this.isLoading = false;
          this.fetchCustomerSubscriptions();
        });
    }
  },
  mounted() {
    this.fetchCustomer();
    this.fetchCustomerSubscriptions();
    this.fetchCustomerOrders();
  }
}
</script>
