import Vue from 'vue'
import Vuex from 'vuex'
import persistPlugin from './utils/persistence';
import AppModule from './app';
import UserModule from './user';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app: AppModule,
    user: UserModule
  },
  plugins: [persistPlugin]
})
