<template>
  <div>
    <h1 class="title">Editar producto</h1>
    <div class="notification is-danger is-light" v-if="errors && errors.length">
      <ul>
        <li v-for="error in errors">{{ error }}</li>
      </ul>
    </div>
    <v-loader v-if="isLoading"/>
    <template v-else-if="product">
      <h2 class="subtitle">{{ product.name }}</h2>
      <product-form :loading="isLoading" :product="product" @save="onSave"/>
    </template>
  </div>
</template>

<script>
export default {
  name: 'EditProduct',
  data() {
    return {
      isLoading: false,
      errors: null,
      product: null
    }
  },
  computed: {
    productId() {
      return this.$route.params.id;
    }
  },
  methods: {
    onSave() {
      this.isLoading = true;
      this.$api.products.updateProduct(this.productId, this.product)
      .then(() => {
        this.$buefy.toast.open({
          message: 'Producto guardado',
          type: 'is-success'
        })
        this.$router.replace('/products')
      })
      .catch(e => {
        const { data } = e.response;
        this.$buefy.toast.open({
          message: 'Ocurrió un error',
          type: 'is-danger'
        })

        if (data) {
          const { errors } = data;
          this.errors = Object.values(errors).reduce((prev, x) => prev.concat(x), []);
        }
      })
      .finally(() => {
        this.isLoading = false;
      })
    },
    fetchProduct() {
      this.isLoading = true;
      this.$api.products.getProduct(this.productId)
      .then(product => {
        this.product = product;
      })
      .finally(() => {
        this.isLoading = false;
      })
    }
  },
  mounted() {
    this.fetchProduct();
  }
}
</script>
