import api from '../api';

const UserModule = {
    namespaced: true,
    state: () => ({
        profile: null,
        isLoading: false,
    }),
    getters: {
        isAuthenticated: state => {
            return state.profile !== null;
        },
        roles: (state, getters) => {
            if (!getters.isAuthenticated)
                return [];

            return state.profile.roles.map(x => x.name);
        },
        isAdmin: (state, getters) => {
            return getters.roles.includes('admin');
        }
    },
    mutations: {
        setIsLoading(state, loading) {
            state.isLoading = loading;
        },
        setUserProfile(state, data) {
            state.profile = data
        },
        clearUserData(state) {
            state.profile = null;
        }
    },
    actions: {
        tryLogin: ({ commit, dispatch }, credentials) => {
            commit('setIsLoading', true)

            return api.auth.login(credentials).then(data => {
                console.log(data);
                return dispatch('fetchUser');
            }).catch(e => {
                commit('setIsLoading', false);
                throw new Error(e);
            })
        },
        fetchUser: ({ commit }) => {
            commit('setIsLoading', true);

            return api.users.fetchCurrentUser().then(data => {
                commit('setUserProfile', data)
            }).catch(e => {
                console.log(e)
            }).finally(() => {
                commit('setIsLoading', false)
            })
        },
        logout: ({ commit }) => {
            commit('setIsLoading', true);

            return api.auth.logout().then(() => {
                commit('clearUserData');
            }).finally(() => {
                commit('setIsLoading', false);
            })
        }
    }
}

export default UserModule;