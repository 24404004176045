import axios from 'axios';
import store from '../store';

/**
 * Create a new Axios client instance
 * @see https://github.com/mzabriskie/axios#creating-an-instance
 */
const getClient = (baseUrl = null, config = {}) => {
  const options = {
    baseURL: baseUrl,
    ...config
  };

  // if (store.getters['users/isAuthenticated']) {
  //     options.headers = {
  //         Authorization: `Bearer ${store.getters['users/accessToken']}`,
  //     };
  // }

  const client = axios.create(options);

  // Add a request interceptor
  // client.interceptors.request.use(
  //     requestConfig => requestConfig,
  //     (requestError) => {
  //         // Handle error
  //
  //         return Promise.reject(requestError);
  //     },
  // );

  client.defaults.headers = {
    'Accept': 'application/json'
  }

  // Add a response interceptor
  client.interceptors.response.use(
      response => {
        if (response.headers['content-type'] !== undefined &&
            response.headers['content-type'] !== 'application/json') {
          return Promise.reject();
        }
        return response;
      },
      (error) => {
          if (error.response && error.response.status >= 500) {
              // Handle error
          }

          if (error.response && error.response.status === 401) {
            store.commit('user/clearUserData')
          }

          return Promise.reject(error);
      },
  );

  return client;
};

class ApiClient {
  constructor(baseUrl = '/api', config = {}) {
    this.client = getClient(baseUrl, config);
  }

  get(url, conf = {}) {
    return this.client.get(url, conf)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  delete(url, conf = {}) {
    return this.client.delete(url, conf)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  head(url, conf = {}) {
    return this.client.head(url, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  options(url, conf = {}) {
    return this.client.options(url, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  post(url, data = {}, conf = {}) {
    return this.client.post(url, data, conf)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  put(url, data = {}, conf = {}) {
    return this.client.put(url, data, conf)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  patch(url, data = {}, conf = {}) {
    return this.client.patch(url, data, conf)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }
}

export { ApiClient };

/**
 * Base HTTP Client
 */
export default {
  // Provide request methods with the default base_url
  get(url, conf = {}) {
    return getClient().get(url, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  delete(url, conf = {}) {
    return getClient().delete(url, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  head(url, conf = {}) {
    return getClient().head(url, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  options(url, conf = {}) {
    return getClient().options(url, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  post(url, data = {}, conf = {}) {
    return getClient().post(url, data, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  put(url, data = {}, conf = {}) {
    return getClient().put(url, data, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  patch(url, data = {}, conf = {}) {
    return getClient().patch(url, data, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
};
