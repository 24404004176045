import PersistedData from '../../services/Persistence';

const mutationWhitelist = [
  'user/setUserProfile',
  'user/clearUserData',
];

const plugin = store => {
  store.subscribe((mutation, state) => {
    if ( mutationWhitelist.includes(mutation.type) ) {
      //  Profile
      const { profile } = state.user;
      new PersistedData('user_profile', profile).persist();
      // PersistedData.persistAll([favTheatersPersist, searchPersist, userPersist]);
    } else if ( mutation.type === 'app/initApp' ) {
      const savedProfile = PersistedData.retrieve('user_profile')
        ? PersistedData.retrieve('user_profile').getData() : null;

      if (savedProfile) {
        store.commit('user/setUserProfile', savedProfile);
      }
    }
  });
};

export default plugin;
