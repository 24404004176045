<template>
  <chart-wrapper>
    <h4 class="title is-6">Tickets por estado</h4>
    <div class="is-relative">
      <v-loader v-if="isLoading"/>
      <pie-chart v-else-if="chartData" :chartdata="chartData"/>
      <p v-else class="has-text-centered">No hay datos disponibles</p>
      <hr>
      <div class="has-text-centered">
        <router-link to="/tickets" class="button is-primary">Ver tickets</router-link>
      </div>
    </div>
  </chart-wrapper>
</template>

<script>
import { colorsByStatus } from '@/utils/charts-helpers';

export default {
  name: 'TicketsByStatus',
  data() {
    return {
      isLoading: false,
      chartData: null,
      // chartData: {
      //   datasets: [{
      //     data: [4, 10],
      //     backgroundColor: [colorsByStatus.success, colorsByStatus.danger],
      //   }],
      //   labels: ['Cerrados', 'Abiertos']
      // }
    }
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      this.$api.reports.getTicketsCount()
      .then(result => {
        let data = [];
        let backgroundColor = [];
        let labels = [];

        for (let status in result) {
          if (result.hasOwnProperty(status)) {
            data.push(result[status]);
            labels.push(
                this.$options.filters.parseTicketStatus(status)
            )
            backgroundColor.push(
                status === 'open' ? colorsByStatus.danger : colorsByStatus.success
            )
          }
        }

        if (data.length) {
          this.chartData = {
            datasets: [{
              data,
              backgroundColor
            }],
            labels
          };
        }
      }).finally(() => {
        this.isLoading = false;
      })
    }
  },
  mounted() {
    this.fetchData();
  }
}
</script>
