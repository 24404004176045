<template>
  <div class="columns is-multiline">
    <template v-if="isAdmin">
      <downloadable-reports/>
      <div class="column is-12">
        <h4 class="title is-5">Órdenes</h4>
      </div>
      <div class="column is-12">
        <router-link to="/orders" class="button is-dark is-light">Ver órdenes</router-link>
      </div>
      <orders-by-status class="column is-4"/>
      <orders-by-agent class="column is-4"/>
      <orders-by-country class="column is-4"/>
      <div class="column is-12">
        <hr>
      </div>
      <div class="column is-12">
        <h4 class="title is-5">Tickets</h4>
      </div>
      <tickets-by-status class="column is-4"/>
    </template>
    <template v-else></template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'DashboardHome',
  computed: {
    ...mapGetters('user', ['isAdmin'])
  }
}
</script>
