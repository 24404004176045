<template>
  <div>
    <div class="columns is-multiline">
      <div class="column is-4">
        <h1 class="title" v-if="order">Órden #{{ order.id }}</h1>
        <b-skeleton size="is-large" v-else/>
      </div>
      <div class="column is-8 has-text-right">

      </div>
      <div class="column is-12">
        <span v-if="order" class="tag is-medium is-light"
              :class="{
                    'is-success': order.status === 'approved',
                    'is-warning': order.status === 'pending',
                    'is-danger': order.status === 'rejected'}">
              {{ order.status | parseOrderStatus }}
        </span>
        <b-skeleton size="is-large" v-else/>
      </div>
      <div class="column is-12 is-paddingless">
        <hr>
      </div>
      <div class="column is-12">
        <div class="columns is-multiline">
          <div class="column is-6">
            <h2 class="title is-4">Cliente</h2>
          </div>
          <div class="column is-6 has-text-right">
            <router-link v-if="order" class="button is-info is-light"
                         :to="{ name: 'customer_detail', params: { id: order.customer.id } }">Ver cliente</router-link>
          </div>
          <div class="column is-4">
            <span class="label">Nombre</span>
            <p v-if="order">{{ order.customer.full_name }}</p>
            <b-skeleton v-else/>
          </div>
          <div class="column is-4">
            <span class="label">Correo electrónico</span>
            <a :href="`mailto:${order.customer.email}`" v-if="order">{{ order.customer.email }}</a>
            <b-skeleton v-else/>
          </div>
          <div class="column is-4">
            <span class="label">Teléfono</span>
            <p v-if="order">{{ order.customer.phone }}</p>
            <b-skeleton v-else/>
          </div>
          <div class="column is-4">
            <span class="label">Dirección</span>
            <template v-if="order">
              <p>{{ order.address.address }}</p>
              <p>{{ order.address.postal_codes }}</p>
              <p>{{ order.address.notes }}</p>
            </template>
            <b-skeleton v-else/>
          </div>
        </div>
      </div>
      <div class="column is-12 is-paddingless">
        <hr>
      </div>
      <div class="column is-12">
        <div class="columns is-multiline">
          <div class="column is-12">
            <h2 class="title is-5">Productos</h2>
          </div>
          <div class="column is-12">
            <b-table
                :loading="isLoading"
                :data="products">
              <b-table-column field="id" label="ID" width="40" numeric v-slot="{row}">
                {{ row.id }}
              </b-table-column>
              <b-table-column field="name" label="Nombre" v-slot="{row}">
                {{ row.name }}
              </b-table-column>
              <b-table-column field="sku" label="SKU" v-slot="{row}">
                {{ row.sku }}
              </b-table-column>
              <b-table-column field="price" label="Precio" v-slot="{row}">
                {{ row.price | formatCurrency }}
              </b-table-column>
              <b-table-column field="type" label="Tipo" v-slot="{row}">
                <span>{{ row.type | parseProductType }}</span>
                <br>
                <span class="tag is-info is-light" v-if="row.type === 'recurrent'">Cada {{ $getProductFrequency(row) }} meses</span>
              </b-table-column>
              <b-table-column label="Acciones" v-slot="{row}">
                <div class="buttons has-addons">
                  <router-link v-if="!row.deleted_at" :to="{ name: 'edit_product', params: { id: row.id } }"
                               class="button is-small is-primary">Ver</router-link>
                </div>
              </b-table-column>
            </b-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrderDetail',
  data() {
    return {
      isLoading: false,
      order: null,
    }
  },
  computed: {
    orderId() {
      return this.$route.params.id;
    },
    products() {
      return this.order ?
          this.order.shopping_cart.items.map(x => x.product) : [];
    }
  },
  methods: {
    fetchOrder() {
      this.isLoading = true;
      this.$api.orders.getOrder(this.orderId)
      .then(result => {
        this.order = result;
      }).finally(() => {
        this.isLoading = false;
      })
    }
  },
  mounted() {
    this.fetchOrder();
  }
}
</script>
