import Vue from 'vue'
import Buefy from "buefy";
import App from './App.vue'
import router from './router'
import store from './store'
import { setupValidation } from './utils/validation';
import { ApiPlugin } from './api';
import filters from './utils/filters';
import functions from "./utils/functions";

Vue.config.productionTip = false

Vue.use(ApiPlugin)
setupValidation(Vue)

Vue.use(Buefy, {
  defaultIconPack: 'fas',
});

for (const filter in filters) {
  if (filters.hasOwnProperty(filter)) {
    Vue.filter(filter, filters[filter])
  }
}

for (const fun in functions) {
  if (functions.hasOwnProperty(fun)) {
    Vue.prototype[`$${fun}`] = functions[fun];
  }
}

const files = require.context('./components/', true, /\.vue$/i);
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
