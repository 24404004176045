export const getRoutes = client => ({
    getCustomers(params) {
        return client.get('customers', { params: params })
    },
    getCustomer(id) {
        return client.get(`customers/${id}`);
    },
    createCustomer(data) {
        return client.post('customers', data);
    },
    updateCustomer(id, data) {
        return client.patch(`customers/${id}`, data);
    },
    getCustomerAddresses(customerId) {
        return client.get(`customers/${customerId}/addresses`);
    },
    getCustomerSubscriptions(customerId) {
        return client.get(`customers/${customerId}/subscriptions`);
    },
    getCustomerOrders(customerId) {
        return client.get(`customers/${customerId}/orders`);
    }
})
