export const getRoutes = client => ({
    getTickets(params) {
        return client.get('tickets', { params: params })
    },
    getTicket(ticketId) {
        return client.get(`tickets/${ticketId}`);
    },
    saveTicket(data) {
        return client.post('tickets', data)
    },
    updateTicket(ticketId, data) {
        return client.patch(`tickets/${ticketId}`, data);
    },
    getTicketNotes(ticketId) {
        return client.get(`tickets/${ticketId}/notes`);
    },
    addTicketNote(ticketId, data) {
        return client.post(`tickets/${ticketId}/notes`, data)
    }
})
