export const getRoutes = client => ({
    createOrder(data) {
        return client.post('orders', data)
    },
    getOrders(params) {
        return client.get('orders', { params: params })
    },
    getOrder(id) {
        return client.get(`orders/${id}`)
    }
})
