<template>
  <div class="columns is-centered is-multiline is-fullwidth">
    <div class="column is-6">
      <figure>
        <img src="../../assets/condenast_logo.png" alt="">
      </figure>
    </div>
    <div class="column is-9">
      <div class="card">
        <div class="card-header">
          <p class="card-header-title">Recuperar contraseña</p>
        </div>
        <div class="card-content">
          <v-loader v-if="isLoading"/>
          <form @submit.prevent="onFormSubmit">
            <ValidationObserver v-slot="{ valid, invalid }">
              <div class="columns is-multiline">
                <div class="column is-12">
                  <v-input v-model="email" rules="required" type="email" vv-name="Correo electrónico"
                           placeholder="Correo electrónico">Correo electrónico
                  </v-input>
                </div>
                <transition name="slide">
                  <div class="column is-12" v-if="message">
                    <div class="message" :class="{'is-danger': errors, 'is-success': !errors}">
                      <div class="message-body">
                        <template v-if="!errors">
                          <p>{{ message }}</p>
                        </template>
                        <template v-else>
                          <ul>
                            <li v-for="error in errors">{{ error }}</li>
                          </ul>
                        </template>
                      </div>
                    </div>
                  </div>
                </transition>
                <div class="column is-12">
                  <div class="field">
                    <button type="submit" class="button is-primary" :disabled="invalid || sentOk">Ingresar</button>
                  </div>
                </div>
              </div>
            </ValidationObserver>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ForgotPassword',
  data() {
    return {
      isLoading: false,
      email: '',
      message: null,
      errors: null,
      sentOk: false
    }
  },
  methods: {
    onFormSubmit() {
      this.isLoading = true;
      this.$api.auth.askPasswordReset({
        email: this.email
      }).then(result => {
        const { message } = result;
        this.message = message;
        this.errors = null;
        this.sentOk = true;
      }).catch(e => {
        const { data } = e.response;
        const { message, errors } = data;

        this.message = message;

        if (errors) {
          this.errors = Object.values(errors).reduce((acc, items) => acc.concat(items), [])
        }
      }).finally(() => {
        this.isLoading = false;
      })
    }
  }
}
</script>
