<template>
  <div id="app">
    <div id="dashboard-wrapper is-relative" v-if="isAuthenticated">
      <v-loader v-if="isLoading"/>
      <v-navbar/>
      <section class="main-content columns is-fullheight">
        <div class="column is-2 is-narrow-mobile is-fullheight section is-hidden-mobile">
          <v-sidebar/>
        </div>
        <div class="container column is-9 is-8-widescreen">
          <div class="section">
            <router-view/>
          </div>
        </div>
      </section>
    </div>
    <div id="auth-section" class="auth-section" v-else>
      <div class="auth-section__wrapper">
        <router-view/>
      </div>
      <div class="auth-section__image">
        <img src="./assets/auth-img.png" alt="">
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  computed: {
    ...mapState('user', ['isLoading']),
    ...mapGetters('user', ['isAuthenticated'])
  },
  methods: {
    ...mapMutations('app', ['initApp']),
    ...mapActions('user', ['fetchUser'])
  },
  beforeMount() {
    this.initApp();
  },
  mounted() {
    if (this.isAuthenticated) {
      this.fetchUser();
    }
  }
}
</script>
<style lang="scss">
  @import "scss/main";
</style>