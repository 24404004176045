<template>
  <ValidationObserver tag="div">
    <form @submit.prevent="onSubmit">
      <label for="search" class="label">Buscar</label>
      <div class="field has-addons">
        <div class="control has-icons-left">
          <input type="text" id="search" class="input" v-model="search" :placeholder="placeholder">
          <span class="icon is-small is-left">
            <i class="fas fa-search"></i>
          </span>
        </div>
        <div class="control">
          <button type="submit" class="button is-primary">Buscar</button>
        </div>
      </div>
      <div class="field" v-if="didSearch">
        <span>Buscando:</span><br>
        <b-tag type="is-primary"
               size="is-medium"
               closable
               @close="clear">
          {{ appliedSearch }}
        </b-tag>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
export default {
  name: 'SearchBlock',
  props: {
    placeholder: {
      type: String,
      default: 'Buscar...'
    }
  },
  data() {
    return {
      didSearch: false,
      appliedSearch: '',
      search: ''
    }
  },
  methods: {
    clear() {
      this.didSearch = false;
      this.appliedSearch = '';
      this.search = '';
      this.$emit('clear');
    },
    onSubmit() {
      this.didSearch = true;
      this.appliedSearch = this.search.toString();
      this.$emit('search', this.appliedSearch);
    }
  }
}
</script>
