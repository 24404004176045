class PersistedData {
  /**
   *
   * @param {string} key
   * @param data
   * @param {Number} expiresAt
   * @returns {PersistedData}
   */
  constructor(key, data, expiresAt = null) {
    this.key = key;
    this.data = data;
    this.expiresAt = expiresAt;

    return this;
  }

  /**
   *
   * @returns {boolean}
   */
  hasExpired() {
    if (this.expiresAt === null) return false;
    const now = new Date();
    return now.getTime() > this.expiresAt;
  }

  getData() {
    return this.data;
  }

  persist() {
    const data = {
      data: this.data,
      expiresAt: this.expiresAt
    };

    const dataString = JSON.stringify(data);
    localStorage.setItem(this.key, dataString);
  }

  static retrieve(key, getData) {
    const data = localStorage.getItem(key);

    if (!data) return null;

    const parsedData = JSON.parse(data);

    const persistedData = new PersistedData(key, parsedData.data, parsedData.expiresAt);

    if (persistedData.hasExpired()) {
      PersistedData.delete(key);
      return null;
    }

    return getData ? persistedData.getData() : persistedData;
  }

  static delete(key) {
    localStorage.removeItem(key);
  }

  /**
   *
   * @param {PersistedData[]} persistObjects
   */
  static persistAll(persistObjects) {
    persistObjects.map(x => { x.persist() });
  }
}

export default PersistedData;
