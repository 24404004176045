var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"title"},[_vm._v("Productos")]),_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-6"},[_c('search-block',{on:{"search":_vm.loadProducts,"clear":_vm.loadProducts}})],1),_c('div',{staticClass:"column is-12"},[(_vm.isLoading)?_c('v-loader'):_vm._e(),_c('b-table',{attrs:{"paginated":"","backend-pagination":"","backend-filtering":"","total":_vm.pagination.total,"per-page":_vm.pagination.per_page,"data":_vm.products},on:{"page-change":_vm.onPageChange}},[_c('b-table-column',{attrs:{"field":"id","label":"ID","width":"40","numeric":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.id)+" ")]}}])}),_c('b-table-column',{attrs:{"field":"name","label":"Nombre"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.name)+" ")]}}])}),_c('b-table-column',{attrs:{"field":"sku","label":"SKU"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.sku)+" ")]}}])}),_c('b-table-column',{attrs:{"field":"price","label":"Precio"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("formatCurrency")(row.price))+" ")]}}])}),_c('b-table-column',{attrs:{"field":"type","label":"Tipo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm._f("parseProductType")(row.type)))]),_c('br'),(row.type === 'recurrent')?_c('span',{staticClass:"tag is-info is-light"},[_vm._v("Cada "+_vm._s(_vm.$getProductFrequency(row))+" meses")]):_vm._e()]}}])}),_c('b-table-column',{attrs:{"label":"Acciones"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"buttons has-addons"},[_c('router-link',{staticClass:"button is-small is-primary",attrs:{"to":{ name: 'edit_product', params: { id: row.id } }}},[_vm._v("Editar")]),_c('button',{staticClass:"button is-small is-danger",on:{"click":function($event){return _vm.promptDelete(row)}}},[_vm._v("Eliminar")])],1)]}}])})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }