var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"title"},[_vm._v(_vm._s(_vm.mainTitle))]),_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-12"},[(_vm.isLoading)?_c('v-loader'):_vm._e(),_c('b-table',{attrs:{"paginated":"","backend-pagination":"","backend-filtering":"","total":_vm.pagination.total,"per-page":_vm.pagination.per_page,"data":_vm.tickets},on:{"page-change":_vm.onPageChange}},[_c('b-table-column',{attrs:{"field":"id","label":"ID","width":"40","numeric":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.id)+" ")]}}])}),_c('b-table-column',{attrs:{"field":"subject","label":"Asunto"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.subject)+" ")]}}])}),_c('b-table-column',{attrs:{"field":"assignee","label":"Asignado a"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.assignee.full_name)+" ")]}}])}),_c('b-table-column',{attrs:{"field":"notes_count","label":"Comentarios"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"tag is-primary"},[_vm._v(_vm._s(row.notes_count))])]}}])}),_c('b-table-column',{attrs:{"field":"created_at","label":"Fecha"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(row.created_at))+" ")]}}])}),_c('b-table-column',{attrs:{"label":"Acciones"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"buttons has-addons"},[_c('router-link',{staticClass:"button is-small is-info is-light",attrs:{"to":{ name: 'ticket_details', params: { id: row.id } }}},[_vm._v("Ver detalles")])],1)]}}])})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }