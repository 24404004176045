import axios from 'axios';
import { ApiClient } from './client';
import { getRoutes as getAuthRoutes } from './auth';
import { getRoutes as getUserRoutes } from './users';
import { getRoutes as getCustomerRoutes } from './customers';
import { getRoutes as getProductRoutes } from './products';
import { getRoutes as getSubscriptionsRoutes } from './subscriptions';
import { getRoutes as getCartRoutes } from './shoppingCart';
import { getRoutes as getCountryRoutes } from './countries';
import { getRoutes as getStateRoutes } from './states';
import { getRoutes as getAddressRoutes } from './addresses';
import { getRoutes as getOrdersRoutes } from './orders';
import { getRoutes as getTicketsRoutes } from './tickets';
import { getRoutes as getReportsRoutes } from './reports';

axios.defaults.withCredentials = true;

const apiClient = new ApiClient(
  process.env.VUE_APP_API_BASE
);

const api = {
  auth: getAuthRoutes(apiClient),
  users: getUserRoutes(apiClient),
  customers: getCustomerRoutes(apiClient),
  products: getProductRoutes(apiClient),
  subscriptions: getSubscriptionsRoutes(apiClient),
  carts: getCartRoutes(apiClient),
  countries: getCountryRoutes(apiClient),
  states: getStateRoutes(apiClient),
  addresses: getAddressRoutes(apiClient),
  orders: getOrdersRoutes(apiClient),
  tickets: getTicketsRoutes(apiClient),
  reports: getReportsRoutes(apiClient)
};

export default api;

const ApiPlugin = {
  install(Vue, options) {
    Vue.prototype.$api = api;
    //  Expose $http to allow one-off calls
    Vue.prototype.$http = new ApiClient();
  }
};

export { ApiPlugin };
