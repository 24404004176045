export const getRoutes = client => ({
    getProducts(params) {
        return client.get('products', { params: params })
    },
    getProduct(productId) {
        return client.get(`products/${productId}`);
    },
    saveProduct(data) {
        return client.post('products', data)
    },
    updateProduct(productId, data) {
        return client.patch(`products/${productId}`, data);
    },
    deleteProduct(productId) {
        return client.delete(`products/${productId}`);
    }
})
