import DashboardHome from '@/views/dashboard/Home.vue'

//  Subscriptions
import SubscriptionsList from '@/views/subscriptions/SubscriptionsList';
import CreateSubscription from '@/views/subscriptions/CreateSubscription';
import GeneralSubscriptionReport from '@/views/subscriptions/GeneralSubscriptionsReport';
//  Customers
import CustomersList from '@/views/customers/CustomersList';
import CustomerDetail from '@/views/customers/CustomerDetail';
//  Products
import ProductList from '@/views/products/ProductList';
import CreateProduct from '@/views/products/CreateProduct';
import EditProduct from '@/views/products/EditProduct';
//  Users
import UsersList from '@/views/users/UsersList';
import CreateUser from '@/views/users/CreateUser';
import EditUser from '@/views/users/EditUser';
//  Orders
import OrdersList from '@/views/orders/OrdersList';
import OrderDetail from '@/views/orders/OrderDetail';
import GeneralOrdersReport from '@/views/orders/GeneralOrdersReport';
//  Tickets
import OpenTicketsList from '@/views/tickets/OpenTicketsList';
import CreateTicket from '@/views/tickets/CreateTicket';
import TicketDetails from "../views/tickets/TicketDetails";

// Reports
import ItemizedPayout from '@/views/reports/GeneratePayout';
import UnifiedReport from '@/views/reports/UnifiedReport';

const sidebarMenu = [
    {
        title: 'General',
        routes: [
            {
                label: 'Dashboard',
                path: '/',
                name: 'Dashboard',
                component: DashboardHome
            }
        ]
    },
    {
        title: 'Órdenes',
        routes: [
            {
                label: 'Todas las órdenes',
                path: '/orders',
                component: OrdersList,
                roles: ['admin']
            },
            {
                label: 'Mis órdenes',
                path: '/orders/mine',
                component: OrdersList,
                props: { ownOrdersOnly: true },
                roles: ['agent']
            },
            {
                path: '/orders/:id',
                name: 'order_detail',
                component: OrderDetail,
                hidden: true
            },
            {
                label: 'Reporte general',
                path: '/orders/report/general',
                name: 'general_orders_report',
                component: GeneralOrdersReport,
                roles: ['admin']
            }
        ]
    },
    {
        title: 'Suscripciones',
        routes: [
            {
                label: 'Ver suscripciones',
                path: '/subscriptions',
                name: 'subscriptions',
                component: SubscriptionsList
            },
            {
                label: 'Crear suscripción',
                path: '/subscriptions/new',
                name: 'create_subscription',
                component: CreateSubscription
            },
            {
                label: 'Reporte general',
                path: '/subscriptions/report/general',
                name: 'general_subs_report',
                component: GeneralSubscriptionReport,
                roles: ['admin']
            }
        ]
    },
    {
        title: 'Clientes',
        routes: [
            {
                label: 'Ver clientes',
                path: '/customers',
                name: 'customers',
                component: CustomersList
                // roles: ['admin']
            },
            {
                path: '/customers/:id',
                name: 'customer_detail',
                component: CustomerDetail,
                hidden: true
            }
        ]
    },
    {
        title: 'Productos',
        routes: [
            {
                label: 'Ver productos',
                path: '/products',
                name: 'products',
                component: ProductList
            },
            {
                label: 'Crear producto',
                path: '/products/new',
                name: 'create_product',
                component: CreateProduct,
                roles: ['admin']
            },
            {
                path: '/products/:id',
                name: 'edit_product',
                component: EditProduct,
                roles: ['admin'],
                hidden: true
            }
        ]
    },
    {
        title: 'Usuarios',
        routes: [
            {
                label: 'Ver usuarios',
                path: '/users',
                name: 'users',
                component: UsersList,
                roles: ['admin']
            },
            {
                label: 'Crear usuario',
                path: '/users/new',
                name: 'create_user',
                component: CreateUser,
                roles: ['admin']
            },
            {
                path: '/users/:id',
                name: 'edit_user',
                component: EditUser,
                roles: ['admin'],
                hidden: true
            }
        ]
    },
    {
        title: 'Tickets',
        routes: [
            {
                label: ({ isAdmin }) => {
                    return isAdmin ? 'Tickets abiertos' : 'Mis tickets';
                },
                path: '/tickets',
                name: 'open_tickets',
                component: OpenTicketsList
            },
            {
                label: 'Crear ticket',
                path: '/tickets/create',
                name: 'create_ticket',
                component: CreateTicket
            },
            {
                path: '/tickets/:id',
                name: 'ticket_details',
                component: TicketDetails,
                hidden: true
            }
        ]
    },
    {
        title: 'Reportes',
        routes: [
            {
                label: 'Reporte Transacciones',
                path: '/reports/payouts/itemized',
                name: 'itemized_payout_report',
                component: ItemizedPayout,
                roles: ['admin']
            },
            {
                label: 'Unificado',
                path: '/reports/unified',
                name: 'unified_report',
                component: UnifiedReport,
                roles: ['admin']
            }
        ]
    }
];

const flattenedRoutes = sidebarMenu.reduce(
    (
        routes,
        item
    ) => {
        return routes.concat(item.routes);
    }, [])

export { sidebarMenu, flattenedRoutes }