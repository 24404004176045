<template>
  <div>
    <h1 class="title">Productos</h1>
    <div class="columns is-multiline">
      <div class="column is-6">
        <search-block @search="loadProducts" @clear="loadProducts"/>
      </div>
      <div class="column is-12">
        <v-loader v-if="isLoading"/>
        <b-table
            paginated
            backend-pagination
            backend-filtering
            :total="pagination.total"
            :per-page="pagination.per_page"
            @page-change="onPageChange"
            :data="products">
          <b-table-column field="id" label="ID" width="40" numeric v-slot="{row}">
            {{ row.id }}
          </b-table-column>
          <b-table-column field="name" label="Nombre" v-slot="{row}">
            {{ row.name }}
          </b-table-column>
          <b-table-column field="sku" label="SKU" v-slot="{row}">
            {{ row.sku }}
          </b-table-column>
          <b-table-column field="price" label="Precio" v-slot="{row}">
            {{ row.price | formatCurrency }}
          </b-table-column>
          <b-table-column field="type" label="Tipo" v-slot="{row}">
            <span>{{ row.type | parseProductType }}</span>
            <br>
            <span class="tag is-info is-light" v-if="row.type === 'recurrent'">Cada {{ $getProductFrequency(row) }} meses</span>
          </b-table-column>
          <b-table-column label="Acciones" v-slot="{row}">
            <div class="buttons has-addons">
              <router-link :to="{ name: 'edit_product', params: { id: row.id } }" class="button is-small is-primary">Editar</router-link>
              <button class="button is-small is-danger" @click="promptDelete(row)">Eliminar</button>
            </div>
          </b-table-column>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductList',
  data() {
    return {
      isLoading: false,
      pagination: {
        page: 1,
        per_page: 10,
        total: 0
      },
      products: []
    }
  },
  methods: {
    deleteProduct(product) {
      this.isLoading = true;
      this.$api.products.deleteProduct(product.id)
      .then(() => {
        this.$buefy.toast.open({
          message: 'Producto eliminado',
          type: 'is-success'
        });
        this.loadProducts();
      })
      .catch(e => {
        this.$buefy.toast.open({
          message: 'Oops! Ocurrió un error...',
          type: 'is-danger'
        })
      })
      .finally(() => {
        this.isLoading = false;
      })
    },
    promptDelete(product) {
      this.$buefy.dialog.confirm({
        title: `¿Eliminar "${product.name}"?`,
        message: '¿Estás seguro de querer eliminar este producto?',
        confirmText: 'Eliminar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteProduct(product)
      })
    },
    onPageChange(page) {
      this.pagination.page = page;
      this.loadProducts()
    },
    loadProducts(search = '') {
      this.isLoading = true;
      this.$api.products.getProducts({
        page: this.pagination.page,
        perPage: this.pagination.per_page,
        q: search
      })
      .then(res => {
        const { data, ...pagination } = res;
        this.products = data;
        this.pagination = pagination
      })
      .finally(() => {
        this.isLoading = false;
      })
    }
  },
  mounted() {
    this.loadProducts();
  }
}
</script>
