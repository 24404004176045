<template>
    <div class="is-relative">
        <v-loader v-if="isCreatingOrder" />
        <h1 class="title">Crear Suscripción</h1>
        <!--  -----------------    -->
        <div class="columns is-multiline box is-relative">
            <v-loader v-show="products.isSavingToCart" />
            <div class="column is-12">
                <h2 class="title is-5">1. Seleccionar Productos</h2>
            </div>
            <div class="column is-6">
                <div class="field">
                    <label class="label" for="product">Buscar producto</label>
                    <b-autocomplete
                        :data="products.data"
                        placeholder="Buscar..."
                        field=""
                        :loading="products.isLoading"
                        @typing="fetchProducts"
                        icon="search"
                        clearable
                        @select="addProduct"
                    >
                        <template slot-scope="props">
                            <div class="media">
                                <div class="media-content">
                                    <p>{{ props.option.name }}</p>
                                    <small>SKU: {{ props.option.sku }}</small
                                    ><br />
                                    <small
                                        ><b>{{ props.option.price | formatCurrency }}</b></small
                                    >
                                </div>
                            </div>
                        </template>
                        <template #empty>Sin resultados</template>
                    </b-autocomplete>
                </div>
            </div>
            <div class="column is-8" v-show="shoppingCart.items.length">
                <h4 class="title is-6">Productos seleccionados:</h4>
                <b-taglist>
                    <b-tag
                        type="is-primary is-medium"
                        v-for="item in shoppingCart.items"
                        :key="item.id"
                        @close="removeProduct(item)"
                        closable
                        >{{ item.product.name }} - {{ item.item_total | formatCurrency }}</b-tag
                    >
                </b-taglist>
            </div>
        </div>
        <hr />
        <!--  -----------------    -->
        <ValidationObserver htmlTag="div" class="columns is-multiline is-relative box" v-slot="{ valid, invalid }">
            <div class="overlay" v-show="!products.selected.length"></div>
            <v-loader v-show="customers.isSaving" />
            <div class="column is-12">
                <h2 class="title is-5">
                    2. Datos del cliente
                    <span class="tag is-success" v-if="customers.isSaved || !customers.isNew">Guardado</span>
                    <span class="tag is-danger" v-if="customers.apiErrorMessage"> Error: {{ customers.apiErrorMessage }} </span>
                </h2>
            </div>
            <div class="column is-6">
                <b-autocomplete
                    ref="customer_autoselect"
                    :data="customers.data"
                    placeholder="Correo electrónico"
                    field="email"
                    :loading="customers.isLoading"
                    @typing="fetchCustomers"
                    icon="envelope"
                    v-model="customers.current.email"
                    @select="selectCustomer"
                >
                    <template slot-scope="props">
                        <div class="media">
                            <div class="media-content">
                                <p>
                                    <b>{{ props.option.first_name }} {{ props.option.last_name }}</b>
                                </p>
                                <small>{{ props.option.email }}</small>
                            </div>
                        </div>
                    </template>
                    <template #empty>Sin resultados</template>
                </b-autocomplete>
            </div>
            <div class="column is-6">
                <button
                    type="button"
                    class="button is-primary is-outlined"
                    v-show="!customers.isNew"
                    @click="clearSelectedCustomer"
                >
                    Limpiar
                </button>
            </div>
            <div class="column is-6">
                <v-input
                    type="text"
                    rules="required"
                    vv-name="Nombre"
                    v-model="customers.current.first_name"
                    id="first_name"
                    :disabled="disabledCustomerFields"
                    >Nombre</v-input
                >
            </div>
            <div class="column is-6">
                <v-input
                    type="text"
                    rules="required"
                    vv-name="Apellido"
                    v-model="customers.current.last_name"
                    id="last_name"
                    :disabled="disabledCustomerFields"
                    >Apellido</v-input
                >
            </div>
            <div class="column is-6">
                <v-input
                    type="tel"
                    rules="required"
                    vv-name="Teléfono"
                    v-model="customers.current.phone"
                    id="phone"
                    :disabled="disabledCustomerFields"
                    >Teléfono</v-input
                >
            </div>
            <div class="column is-12">
                <button type="button" class="button is-primary" :disabled="invalid" @click="createOrUpdateCustomer">
                    Guardar
                </button>
            </div>
        </ValidationObserver>
        <hr />
        <!--  -----------------    -->
        <ValidationObserver htmlTag="div" class="columns is-multiline is-relative box" v-slot="{ valid, invalid }">
            <div class="overlay" v-show="!customers.current.id"></div>
            <v-loader v-show="address.isSaving || address.isLoading" />
            <div class="column is-12">
                <h2 class="title is-5">
                    3. Dirección
                    <span class="tag is-success" v-if="address.isSaved">Guardado</span>
                </h2>
            </div>
            <template v-if="!customers.isNew">
                <div class="column is-12">
                    <div class="field has-addons">
                        <b-radio-button
                            v-model="address.useExisting"
                            :native-value="true"
                            type="is-primary"
                            :disabled="!allowExistingAddress"
                        >
                            <span>Dirección existente</span>
                        </b-radio-button>
                        <b-radio-button v-model="address.useExisting" :native-value="false" type="is-primary">
                            <span>Dirección nueva</span>
                        </b-radio-button>
                    </div>
                </div>
                <div class="columns is-12">
                    <hr />
                </div>
            </template>
            <template v-if="address.useExisting">
                <div class="column is-4" v-for="address in address.customerAddresses" :key="address.id">
                    <b-radio v-model="selectedAddress" :native-value="address.id" type="is-primary">
                        <div>
                            <p>{{ address.address }}</p>
                            <p>
                                <b>{{ address.city.name }}</b
                                >, {{ address.city.state.name }}
                            </p>
                        </div>
                    </b-radio>
                </div>
            </template>
            <template v-else>
                <div class="column is-12">
                    <v-input type="text" rules="required" vv-name="Dirección" v-model="address.data.address" id="address">
                        Dirección
                    </v-input>
                </div>
                <div class="column is-6">
                    <v-select rules="required" vv-name="País" v-model="address.countryId" :loading="address.isLoadingCountries">
                        <span>País</span>
                        <template slot="options">
                            <option :value="country.id" v-for="country in address.countries">
                                {{ country.name }}
                            </option>
                        </template>
                    </v-select>
                </div>
                <div v-if="isMXSelected" class="column columns is-multiline is-gapless is-6">
                    <label class="label" for="product"> Consultar código Postal </label>
                    <ValidationProvider
                        :name="'Código postal'"
                        :ref="'postal-code'"
                        rules="required"
                        class="column is-12"
                        v-slot="{ errors, failed }"
                    >
                        <b-autocomplete
                            field="c_CP"
                            v-model="address.data.postal_codes"
                            ref="postal_code_autoselect"
                            placeholder="P. ej. 01000"
                            :data="address.postalCodesData"
                            :loading="address.isLoadingMXPostalCodes"
                            @typing="fetchMXPostalCodes"
                            @select="populateAddress"
                        >
                            <template slot-scope="props">
                                <div class="media">
                                    <div class="media-content">
                                        <div class="is-flex is-flex-direction-column">
                                            <div class="name-data">
                                                <b>
                                                    {{ props.option.d_asenta }}
                                                </b>
                                                -
                                                {{ props.option.d_tipo_asenta }}
                                            </div>
                                            <div class="state-data">
                                                {{ props.option.d_mnpio }},
                                                {{ props.option.d_estado }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template #empty>Sin resultados</template>
                        </b-autocomplete>
                        <small class="help is-danger" v-if="errors">{{ errors[0] }}</small>
                    </ValidationProvider>
                </div>
                <v-input
                    class="column is-6"
                    type="text"
                    rules="required"
                    vv-name="Código postal"
                    v-model="address.data.postal_codes"
                    id="address"
                    placeholder="P. ej. 01000"
                    v-else
                >
                    Código postal
                </v-input>
                <template v-if="address.isShowingAddressDetails">
                    <div class="column is-6">
                        <v-select
                            rules="required"
                            vv-name="Estado"
                            v-model="address.stateId"
                            :loading="address.isLoadingStates"
                            :disabled="!address.states.length || address.isPopulating"
                        >
                            <span>Estado / Provincia / Región</span>
                            <template slot="options">
                                <option :value="state.id" v-for="state in address.states">
                                    {{ state.name }}
                                </option>
                            </template>
                        </v-select>
                    </div>
                    <div class="column is-6">
                        <v-select
                            rules="required"
                            vv-name="Ciudad"
                            v-model="address.cityId"
                            :loading="address.isLoadingCities"
                            :disabled="!address.cities.length || address.isPopulating"
                        >
                            <span>Ciudad</span>
                            <template slot="options">
                                <option :value="city.id" v-for="city in address.cities">
                                    {{ city.name }}
                                </option>
                            </template>
                        </v-select>
                    </div>

                    <div class="column is-6">
                        <v-input
                            type="text"
                            vv-name="Asentamiento"
                            v-model="address.data.district"
                            id="address"
                            :disabled="address.isPopulating"
                        >
                            {{ isMXSelected ? 'Asentamiento' : 'Barrio / Distrito / Suburbio ' }}
                        </v-input>
                    </div>
                    <div class="column is-6">
                        <v-input type="textarea" v-model="address.data.notes" id="notes">
                            Entre calles y referencias (notas)
                        </v-input>
                    </div>
                </template>
                <div class="column is-12">
                    <button type="button" class="button is-primary" :disabled="invalid" @click="saveAddress">Guardar</button>
                </div>
            </template>
        </ValidationObserver>
        <hr />
        <div class="columns is-multiline is-relative box">
            <div class="column is-12">
                <div class="columns is-multiline">
                    <div class="column is-4">
                        <div class="field">
                            <label class="label">Pago por terminal</label>
                            <b-switch v-model="orderDetails.is_terminal" true-value="1" false-value="0">
                                {{ orderDetails.is_terminal == 1 ? 'Sí' : 'No' }}
                            </b-switch>
                        </div>
                    </div>
                    <div class="column is-6" v-if="orderDetails.is_terminal">
                        <v-input type="text" vv-name="Referencia" v-model="orderDetails.reference" id="address"
                            >Referencia</v-input
                        >
                    </div>
                </div>
            </div>
        </div>
        <hr />
        <div class="columns is-multiline is-relative box">
            <button class="button is-primary" :disabled="!canCreateOrder || loadingCheckout" @click="createOrder">
                Crear suscripción
            </button>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { debounce, sortBy } from 'lodash';

const defaultCustomer = () => ({
    email: '',
    first_name: '',
    last_name: '',
    phone: ''
});

export default {
    name: 'CreateSubscription',
    data() {
        return {
            loadingCheckout: false,
            selectedAddress: null,
            isCreatingOrder: false,
            shoppingCart: {
                data: null,
                items: []
            },
            orderDetails: {
                is_terminal: false,
                reference: ''
            },
            products: {
                selected: [],
                isLoading: false,
                isSavingToCart: false,
                data: []
            },
            customers: {
                data: [],
                isNew: true,
                isLoading: false,
                isSaving: false,
                current: defaultCustomer(),
                isSaved: false,
                apiErrorMessage: ''
            },
            address: {
                isLoadingCountries: false,
                isLoadingStates: false,
                isLoadingCities: false,
                isLoadedCities: false,
                isLoadingMXPostalCodes: false,
                isPopulating: false,
                isSaving: false,
                isLoading: false,
                isSaved: false,
                isMXSelected: false,
                isShowingAddressDetails: false,
                customerAddresses: [],
                countries: [],
                states: [],
                cities: [],
                postalCodesData: [],
                countryId: null,
                stateId: null,
                cityId: null,
                useExisting: false,
                data: {
                    address: '',
                    postal_codes: '',
                    notes: '',
                    district: ''
                }
            }
        };
    },
    computed: {
        stripeKey() {
            return 'pk_live_51IDuDGCVU1PFg1mOEzm7vHsBKdxeHZDOCHoQfoAasNwV72XASXRmB9d0HhZq5NE1iycJ0GAgGfvgnnPgsxpftX0B00fogXaqeB';
        },
        disabledCustomerFields() {
            return this.customers.isLoading || this.customers.isSaving;
        },
        allowExistingAddress() {
            return this.address.customerAddresses.length > 0 && !this.address.isSaved;
        },
        canCreateOrder() {
            return this.selectedAddress && this.customers.current.id && this.products.selected.length > 0;
        },
        isMXSelected() {
            return this.address.countryId === 142;
        }
    },
    methods: {
        createOrder() {
            this.loadingCheckout = true;
            const data = {
                ...this.orderDetails,
                address_id: this.selectedAddress,
                customer_id: this.customers.current.id,
                shopping_cart_id: this.shoppingCart.data.id
            };

            this.isCreatingOrder = true;

            this.$api.orders
                .createOrder(data)
                .then((data) => {
                    const { stripe_checkout } = data;

                    if (this.orderDetails.is_terminal) {
                        this.$router.replace('/orders');
                    } else {
                        const stripe = Stripe(this.stripeKey);
                        stripe.redirectToCheckout({ sessionId: stripe_checkout.id });
                    }
                })
                .finally(() => {
                    this.isCreatingOrder = false;
                });
        },
        showConfirmation(text) {
            this.$buefy.toast.open({
                message: text,
                type: 'is-success'
            });
        },
        saveAddress() {
            const data = {
                ...this.address.data,
                city_id: this.address.cityId,
                customer_id: this.customers.current.id
            };

            this.address.isSaving = true;

            this.$api.addresses
                .createAddress(data)
                .then((data) => {
                    this.address.data = data;
                    this.selectedAddress = data.id;
                    this.address.isSaved = true;
                })
                .finally(() => {
                    this.address.isSaving = false;
                });
        },
        createOrUpdateCustomer() {
            const customerData = this.customers.current;

            this.customers.isSaving = true;

            if (this.customers.isNew) {
                this.$api.customers
                    .createCustomer(customerData)
                    .then((data) => {
                        this.customers.current = data;
                        this.showConfirmation('Cliente guardado');
                        this.customers.isSaved = true;
                    })
                    .catch((error) => {
                        this.customers.apiErrorMessage = error.response.data.message;
                    })
                    .finally(() => {
                        this.customers.isSaving = false;
                    });
            } else {
                this.$api.customers
                    .updateCustomer(customerData.id, customerData)
                    .then((data) => {
                        this.customers.current = data;
                        this.showConfirmation('Datos del Cliente actualizados');
                        this.customers.isSaved = true;
                    })
                    .finally(() => {
                        this.customers.isSaving = false;
                    });
            }
        },
        selectCustomer(customer) {
            if (!customer) return;

            this.customers.current = customer;
            this.customers.isNew = false;
            this.customers.apiErrorMessage = '';
        },
        fetchCustomerAddresses() {
            this.address.isLoading = true;
            this.$api.customers
                .getCustomerAddresses(this.customers.current.id)
                .then((data) => {
                    this.address.customerAddresses = data;
                    if (data.length) {
                        this.address.useExisting = true;
                        this.selectedAddress = data[0].id;
                    }
                })
                .finally(() => {
                    this.address.isLoading = false;
                });
        },
        clearSelectedCustomer() {
            const { customer_autoselect } = this.$refs;
            this.customers.current = defaultCustomer();
            this.customers.isNew = true;
            customer_autoselect.setSelected('');
        },
        createShoppingCart() {
            this.products.isSavingToCart = true;
            this.$api.carts.createCart().then((data) => {
                this.shoppingCart.data = data;
                this.products.isSavingToCart = false;
            });
        },
        addProduct(product) {
            if (!product) return;

            const { selected } = this.products;

            const existing = selected.find((x) => x.id === product.id);

            if (!existing) {
                this.products.selected = [...selected, product];

                this.products.isSavingToCart = true;
                this.$api.carts
                    .addCartItem({
                        shopping_cart_id: this.shoppingCart.data.id,
                        product_id: product.id,
                        quantity: 1,
                        item_price: product.price,
                        item_total: product.price
                    })
                    .then(() => {
                        this.fetchCartItems();
                    })
                    .finally(() => {
                        this.products.isSavingToCart = false;
                    });
            }
        },
        removeProduct(item) {
            this.products.isSavingToCart = true;

            this.$api.carts
                .deleteCartItem(item.id)
                .then(() => {
                    this.fetchCartItems();
                })
                .finally(() => {
                    this.products.isSavingToCart = false;
                });
        },
        fetchCartItems() {
            this.$api.carts.getCartItems(this.shoppingCart.data.id).then((data) => {
                this.shoppingCart.items = data;
            });
        },
        fetchCustomers: debounce(function (value) {
            if (!value.length) {
                this.customers.data = [];
                return;
            }

            this.customers.isLoading = true;

            this.$api.customers
                .getCustomers({
                    q: value
                })
                .then((result) => {
                    this.customers.data = result.data;
                })
                .finally(() => {
                    this.customers.isLoading = false;
                });
        }, 500),
        fetchProducts: debounce(function (value) {
            if (!value.length) {
                this.products.data = [];
                return;
            }

            this.products.isLoading = true;

            this.$api.products
                .getProducts({
                    q: value
                })
                .then((result) => {
                    this.products.data = result.data;
                })
                .finally(() => {
                    this.products.isLoading = false;
                });
        }, 500),
        fetchMXPostalCodes: debounce(async function (value) {
            this.address.isLoadingMXPostalCodes = true;

            if (!value.length) {
                this.address.postalCodes = [];
                this.address.isLoadingMXPostalCodes = false;
                return;
            }

            this.$api.addresses
                .getMXZipCodes(value)
                .then((data) => {
                    this.address.postalCodesData = sortBy(data.asentamientos, ['d_asenta']).map((asenta) => {
                        const asentaSlug = asenta.slug_asenta.split('-');
                        const zipCode = asentaSlug[asentaSlug.length - 1];

                        asenta.c_CP = zipCode;
                        return asenta;
                    });
                })
                .catch((err) => {
                    this.address.isLoadingMXPostalCodes = false;
                    this.address.isShowingAddressDetails = false;
                })
                .finally(() => {
                    this.address.isLoadingMXPostalCodes = false;
                });
        }, 300),
        fetchCountries() {
            this.address.isLoadingCountries = true;

            this.$api.countries
                .getCountries()
                .then((data) => {
                    this.address.countries = data;
                })
                .finally(() => {
                    this.address.isLoadingCountries = false;
                });
        },
        fetchCountryStates(countryId) {
            this.address.isLoadingStates = true;

            this.$api.countries
                .getCountryStates(countryId)
                .then((data) => {
                    this.address.states = data;
                })
                .finally(() => {
                    this.address.isLoadingStates = false;
                });
        },
        fetchStateCities(stateId) {
            this.address.isLoadingCities = true;

            this.$api.states
                .getStateCities(stateId)
                .then((data) => {
                    this.address.cities = data;
                })
                .finally(() => {
                    this.address.isLoadingCities = false;
                });
        },
        populateAddress(data) {
            if (!data) return;
            this.address.isShowingAddressDetails = false;
            this.address.isPopulating = true;
            this.address.isLoadingMXPostalCodes = true;

            this.populateState(data.d_estado);

            setTimeout(() => {
                this.populateCity(data.d_ciudad);
                this.address.data.district = data.d_asenta;
                this.address.isShowingAddressDetails = true;
                this.address.isLoadingMXPostalCodes = false;
            }, 1500);
        },
        populateCity(cityName) {
            if (cityName === 'Ciudad de México') cityName = 'Mexico City';
            this.address.cityId = this.address.cities.filter((city) => city.name === cityName)[0].id;
        },
        populateState(stateName) {
            if (stateName === 'Ciudad de México') stateName = 'Mexico City';
            this.address.stateId = this.address.states.filter((state) => state.name === stateName)[0].id;
        },
        resetAddressDetails() {
            this.address.postalCodesData = [];
            this.address.isPopulating = false;
            this.address.stateId = null;
            this.address.cityId = null;
            this.address.data.postal_codes = '';
            this.address.data.notes = '';
            this.address.data.district = '';
            this.address.isShowingAddressDetails = !this.isMXSelected ? true : false;
        }
    },
    beforeMount() {
        this.createShoppingCart();
        this.fetchCountries();
    },
    watch: {
        'address.countryId'(value) {
            this.fetchCountryStates(value);
            this.resetAddressDetails();
        },
        'address.stateId'(value) {
            this.fetchStateCities(value);
        },
        'address.data.postal_codes'(value) {
            if (!value) {
                this.resetAddressDetails();
            }
        },
        'address.useExisting'() {
            // this.address.selectedAddress = null;
        },
        'customers.isNew'(value) {
            if (value) {
                this.address.customerAddresses = [];
                this.address.useExisting = false;
            } else {
                this.fetchCustomerAddresses();
            }
        }
    }
};
</script>
