export const getRoutes = client => ({
    createCart() {
        return client.post('shopping-cart', { is_active: true })
    },
    addCartItem(data) {
        return client.post('shopping-cart-items', data)
    },
    getCartItems(cartId) {
        return client.get(`shopping-cart/${cartId}/items`)
    },
    deleteCartItem(itemId) {
        return client.delete(`shopping-cart-items/${itemId}`)
    }
})
