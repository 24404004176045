<template>
  <ValidationProvider :name="vvName" :rules="rules" v-slot="{ errors, valid, invalid, validated, required }" tag="div">
    <div class="field">
      <label class="label" :for="fieldId">
        <slot></slot>
        <span v-if="required">*</span>
      </label>
      <div class="select is-fullwidth"
           :class="{'is-loading': loading, 'is-success': validated && valid, 'is-danger': validated && invalid}">
        <select v-model="innerValue"
                v-bind="fieldAttributes"
                class="input">
          <slot name="options"></slot>
        </select>
      </div>
    </div>
  </ValidationProvider>
</template>

<script>
import InputMixin from "./InputMixin";

export default {
  inheritAttrs: false,
  name: 'VSelect',
  mixins: [InputMixin],
  props: {
    vvName: {
      type: String
    },
    value: {
      type: [String, Number]
    },
    rules: {
      type: [String, Object]
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      innerValue: ''
    }
  },
  computed: {
    fieldAttributes() {
      const {id, ...attrs} = this.$attrs;

      return {
        ...attrs,
        id: this.fieldId,
      };
    }
  },
  watch: {
    value(val) {
      this.innerValue = val;
    },
    innerValue(val) {
      this.$emit('input', val)
    }
  },
  beforeMount() {
    if (this.value !== undefined) {
      this.innerValue = this.value;
    }
  }
}
</script>

<style scoped>

</style>