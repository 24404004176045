import Vue from 'vue';
import VueRouter from 'vue-router';
import { flattenedRoutes } from '@/utils/navigation';
import Store from '@/store';
import Login from '@/views/auth/Login';
import ForgotPassword from '@/views/auth/ForgotPassword';
import ResetPassword from '@/views/auth/ResetPassword';
import PersistedData from "../services/Persistence";

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: (to, from, next) => {
      if (Store.getters['user/isAuthenticated']) next({ name: 'Dashboard' })
      else next()
    }
  },
  {
    path: '/forgot-password',
    name: 'forgot_password',
    component: ForgotPassword,
    beforeEnter: (to, from, next) => {
      if (Store.getters['user/isAuthenticated']) next({ name: 'Dashboard' })
      else next()
    }
  },
  {
    path: '/reset-password',
    name: 'reset_password',
    component: ResetPassword,
    beforeEnter: (to, from, next) => {
      if (Store.getters['user/isAuthenticated']) next({ name: 'Dashboard' })
      else next()
    }
  },
  ...flattenedRoutes.map(route => {
    const { roles : routeRoles } = route;
    if (!routeRoles) return route;

    const persistedProfile = PersistedData.retrieve('user_profile');

    if (!persistedProfile) return route;

    const user = persistedProfile.getData();
    if (!user) return route;
    const userRoles = user.roles;
    if (!user || !userRoles || !userRoles.length) return route;

    let allow = false;

    for (let role of userRoles) {
      if (routeRoles.includes(role.name)) {
        allow = true;
      }
    }

    if (!allow) {
      route.beforeEnter = (to, from, next) => {
        next({ name: 'Dashboard' })
      }
    }

    return route;
  })
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkExactActiveClass: 'is-active',
  routes
})

Store.subscribe((mutation => {
  if (mutation.type === 'user/clearUserData') {
    router.replace('/login')
  }
}))

router.beforeEach((to, from, next) => {
  const blacklist = [
      'Login',
      'forgot_password',
      'reset_password'
  ]
  setTimeout(() => {
    if (!blacklist.includes(to.name) && !Store.getters['user/isAuthenticated']) next({ name: 'Login' })
    else next()
  }, 10)
})

export default router
