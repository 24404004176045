<template>
    <div class="card-container">
        <div
            class="column"
            :class="{
                'card is-6': statusData.status !== 'availability_info',
                'is-12': statusData.status === 'availability_info'
            }"
        >
            <div class="card-content has-text-centered p-2">
                <div class="content__text mb-4">
                    <template v-if="statusData.status === 'processing'">
                        <h3 class="title is-5 has-text-centered has-text-warning mb-4">Procesando Datos...</h3>
                        <p class="has-text-grey-light">Esta acción puede tardar unos minutos.</p>
                        <p>Tiempo estimado: <b>5 - 10 Minutos.</b></p>
                    </template>
                    <template v-else-if="statusData.status === 'stripe_pending'">
                        <p class="p-2 title is-5 stripe-title">Recolectando datos de Stripe...</p>
                        <p class="has-text-grey-light">Esta acción puede tardar unos minutos.</p>
                        <p>Tiempo estimado: <b>5 - 10 Minutos.</b></p>
                    </template>
                    <template v-else-if="statusData.status === 'ready'">
                        <h3 class="title is-5 has-text-centered has-text-success mb-4">Generado</h3>
                        <b-field label="Link de descarga" class="has-text-left">
                            <b-tooltip label="Copiar" position="is-right">
                                <b-button class="download-btn" @click.prevent="copyLink()" expanded>
                                    {{ statusData.info.download_link }}
                                </b-button>
                            </b-tooltip>
                        </b-field>
                    </template>
                    <template v-else-if="statusData.status === 'availability_info'">
                        <b-notification class="is-warning is-light" :closable="false">
                            <p class="p-2">
                                El proximo Reporte Estará disponible el:
                                <b class="ml-1"> {{ formatLocaleDate(statusData.info.available_date) }}.</b>
                            </p>
                        </b-notification>
                    </template>
                    <template v-else-if="statusData.status === 'unknown'">
                        <h3 class="title is-3 has-text-grey-light has-text-danger mb-4">Reporte no disponible</h3>
                    </template>
                    <template v-else>
                        <h3 class="title is-3 has-text-grey-light has-text-success mb-4">Estado no conocido.</h3>
                    </template>
                </div>
                <div class="content__actions">
                    <b-button
                        v-if="statusData.status !== 'availability_info'"
                        class="mr-3 close-button"
                        type="is-primary-light"
                        @click="closeCard"
                    >
                        Cerrar
                    </b-button>
                    <b-button
                        v-if="statusData.status === 'ready'"
                        class="download-button"
                        type="is-primary"
                        @click.prevent="download()"
                    >
                        Descargar
                    </b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ReportStatusCard',
    props: {
        statusData: {
            type: Object,
            default: null
        }
    },
    methods: {
        formatLocaleDate(date) {
            const options = {
                timeStyle: 'short',
                dateStyle: 'medium'
            };
            return new Date(date).toLocaleString('es-MX', options);
        },
        download() {
            const link = document.createElement('a');
            link.href = this.statusData.info.download_link;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        getMonthName(monthNumber) {
            const date = new Date();
            date.setMonth(monthNumber - 1);
            const monthName = date.toLocaleString('es-MX', { month: 'long' });
            return monthName.charAt(0).toLocaleUpperCase() + monthName.slice(1);
        },
        closeCard() {
            this.$emit('cardClosed');
        },
        async copyLink() {
            try {
                await navigator.clipboard.writeText(this.statusData.info.download_link);
                this.$buefy.toast.open({
                    hasIcon: true,
                    message: 'Se copió el Link',
                    type: 'is-success'
                });
            } catch (error) {
                console.error(error);
                this.$buefy.notification.open({
                    message: 'No se pudo copiar el link',
                    type: 'is-danger',
                    hasIcon: true,
                    position: 'is-bottom',
                    duration: 2500
                });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.card-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .content__actions {
        width: 100%;
    }

    &.last-report-card {
        .content__actions {
            .close-button {
                display: none !important;
            }

            .download-button {
                width: 100% !important;
            }
        }
    }
}

.download-btn {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    overflow-x: scroll;
    user-select: text;

    text-decoration: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

.b-tooltip {
    display: block !important;
}

.stripe-title {
    color: rgb(98, 90, 250);
}
</style>
