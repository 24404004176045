const AppModule = {
    namespaced: true,
    state: () => ({
        initAt: null
    }),
    getters: {
        isInit: state => {
            return state.initAt !== null
        }
    },
    mutations: {
        initApp(state) {
            state.initAt = new Date();
        }
    }
}
export default AppModule
