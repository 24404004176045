export const getRoutes = client => ({
    getSubscriptions(params) {
        return client.get('subscriptions', { params: params })
    },
    sendProducts(id, data) {
        return client.post(`subscriptions/${id}/products/send`, data);
    },
    massSendProducts(data) {
        return client.post('subscriptions/send/products', data);
    },
    cancel(id) {
        return client.post(`subscriptions/${id}/cancel`);
    },
    resume(id) {
        return client.post(`subscriptions/${id}/resume`);
    }
})
