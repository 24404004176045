<template>
    <div class="cn-loader-wrapper">
        <div class="cn-loader" :class="{'is-small': small}"></div>
    </div>
</template>

<script>
export default {
    name: 'VLoader',
    props: {
        small: {
            type: Boolean,
            default: false
        }
    }
}
</script>

