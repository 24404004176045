<template>
  <ValidationObserver v-slot="{ valid, invalid }">
    <form @submit.prevent="save">
      <div class="columns">
        <div class="column is-10 is-relative">
          <v-loader v-if="loading"/>
          <div class="columns is-multiline">
            <div class="column is-6">
              <v-input rules="required" vv-name="Nombre" v-model="userData.first_name">Nombre</v-input>
            </div>
            <div class="column is-6">
              <v-input rules="required" vv-name="Apellido" v-model="userData.last_name">Apellido</v-input>
            </div>
            <div class="column is-6">
              <v-input rules="required|email" vv-name="Correo electrónico" v-model="userData.email">Correo electrónico</v-input>
            </div>
            <div class="column is-12" v-if="!isEdit">
              <div class="columns">
                <div class="column is-6">
                  <v-input rules="required|confirmed:confirmation|min:8" vv-name="Contraseña" type="password"
                           v-model="userData.password">Contraseña</v-input>
                </div>
                <div class="column is-6">
                  <v-input rules="required" vv-name="Confirmar contraseña" vid="confirmation" type="password"
                           v-model="userData.password_confirmation">Confirmar contraseña</v-input>
                </div>
              </div>
            </div>
            <div class="column is-12 is-paddingless">
              <hr>
            </div>
            <div class="column is-6">
              <v-select rules="required" vv-name="Rol" v-model="userData.role">
                <span>Rol</span>
                <template slot="options">
                  <option value="agent">Agente</option>
                  <option value="admin">Admin</option>
                </template>
              </v-select>
            </div>
            <div class="column is-6" v-if="isEdit">
              <div class="field">
                <label class="label">¿Activo?</label>
                <b-switch v-model="userData.is_active" true-value="1" false-value="0">
                  {{ userData.is_active == 1 ? 'Sí' : 'No' }}
                </b-switch>
              </div>
            </div>
            <div class="column is-12">
              <button class="button is-primary" type="submit" :disabled="invalid">Guardar</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
export default {
  name: 'UserForm',
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    user: {
      type: Object,
      default() {
        return {
          first_name: '',
          last_name: '',
          email: '',
          password: '',
          password_confirmation: '',
          role: 'agent'
        }
      }
    }
  },
  data() {
    return {
      userData: null
    }
  },
  methods: {
    save() {
      this.$emit('update:user', this.userData)
      this.$emit('save');
    }
  },
  beforeMount() {
    this.userData = this.user;
  }
}
</script>

<style scoped>

</style>