<template>
  <div class="is-relative">
    <v-loader v-if="isSaving"/>
    <h1 class="title">Crear ticket</h1>
    <div class="notification is-danger is-light" v-if="errors && errors.length">
      <ul>
        <li v-for="error in errors">{{ error }}</li>
      </ul>
    </div>
    <ValidationObserver v-slot="{ valid, invalid }">
      <form @submit.prevent="saveTicket">
        <div class="columns is-multiline">
          <div class="column is-6">
            <v-input rules="required" vv-name="Asunto" name="subject" v-model="ticketData.subject">Asunto</v-input>
          </div>
        </div>
        <div class="columns is-multiline">
        <div class="column is-12">
          <h3 class="title is-5">Datos de contacto</h3>
        </div>
        <div class="column is-6">
          <div class="field">
            <label class="label">Cliente</label>
            <b-autocomplete
                :data="customers.data"
                placeholder="Buscar..."
                v-model="customers.selected.full_name"
                field="full_name"
                :loading="customers.isLoading"
                @typing="fetchCustomers"
                icon="search"
                clearable
                @select="selectCustomer">

              <template slot-scope="{option}">
                <div class="media">
                  <div class="media-content">
                    <p>{{ option.full_name }}</p>
                    <small>{{ option.email }}</small><br>
                  </div>
                </div>
              </template>
              <template #empty>Sin resultados</template>
            </b-autocomplete>
            <p class="help">Opcional</p>
          </div>
        </div>
        <div class="column is-6"></div>
        <div class="column is-6">
          <v-input rules="required|email" vv-name="Correo electrónico" type="email"
                   name="email" v-model="ticketData.email">Correo electrónico</v-input>
        </div>
        <div class="column is-6">
          <v-input rules="required" vv-name="Teléfono" type="phone"
                   name="email" v-model="ticketData.phone">Teléfono</v-input>
        </div>
        <div class="column is-12 is-paddingless">
          <hr>
        </div>
        <div class="column is-6">
          <div class="field">
            <label class="label">Asignar a</label>
            <b-autocomplete
                :data="users.data"
                placeholder="Buscar..."
                v-model="users.selected.full_name"
                field="full_name"
                :loading="users.isLoading"
                @typing="fetchUsers"
                icon="search"
                clearable
                @select="assignToUser">

              <template slot-scope="{option}">
                <div class="media">
                  <div class="media-content">
                    <p>{{ option.full_name }}</p>
                    <small>{{ option.email }}</small><br>
                  </div>
                </div>
              </template>
              <template #empty>Sin resultados</template>
            </b-autocomplete>
          </div>
        </div>
        <div class="column is-6"></div>
        <div class="column is-6">
          <v-input rules="required" vv-name="Comentario" name="note" type="textarea"
                   v-model="ticketData.note">Comentario</v-input>
        </div>
        <div class="column is-12">
          <button class="button is-primary" type="submit" :disabled="invalid || !ticketData.assigned_to">Crear</button>
        </div>
      </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import {debounce} from "lodash";

export default {
  name: 'CreateTicket',
  data() {
    return {
      isSaving: false,
      errors: null,
      ticketData: {
        subject: '',
        note: '',
        email: '',
        phone: '',
        assigned_to: null,
        customer_id: null,
      },
      customers: {
        data: [],
        isLoading: false,
        selected: {
          full_name: ''
        }
      },
      users: {
        isLoading: false,
        data: [],
        selected: {
          full_name: ''
        },
      },
    }
  },
  methods: {
    saveTicket() {
      this.isSaving = true;

      this.$api.tickets.saveTicket(this.ticketData)
      .then(() => {
        this.$buefy.toast.open({
          message: 'Ticket creado',
          type: 'is-success'
        });

        this.$router.replace('/tickets');
      }).finally(() => {
        this.isSaving = false;
      })
    },
    assignToUser(user) {
      if (user) {
        this.ticketData.assigned_to = user.id
      } else {
        this.ticketData.assigned_to = null;
      }
    },
    fetchUsers: debounce(function (value) {
      if (!value.length) {
        this.users.data = []
        return
      }

      this.users.isLoading = true;

      this.$api.users.getUsers({
        q: value,
        role: 'agent'
      }).then(result => {
        this.users.data = result.data
      }).finally(() => {
        this.users.isLoading = false;
      })

    }, 500),
    fetchCustomers: debounce(function (value) {
      if (!value.length) {
        this.customers.data = []
        return
      }

      this.customers.isLoading = true;

      this.$api.customers.getCustomers({
        q: value
      }).then(result => {
        this.customers.data = result.data
      }).finally(() => {
        this.customers.isLoading = false;
      })
    }, 500),
    selectCustomer(customer) {
      if (customer !== null) {
        this.customers.selected = customer;
      } else {
        this.customers.selected = {
          full_name: ''
        }
      }
    }
  },
  watch: {
    'customers.selected'(customer) {
      if (customer.id !== null) {
        const { id, email, phone } = customer;
        this.ticketData.customer_id = id;
        this.ticketData.email = email;
        this.ticketData.phone = phone;
      } else {
        this.ticketData.customer_id = null;
        this.ticketData.email = '';
        this.ticketData.phone = '';
      }
    }
  }
}
</script>
