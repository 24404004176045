<template>
  <div>
    <h1 class="title">Usuarios</h1>
    <div class="columns is-multiline">
      <div class="column is-6">
        <search-block @search="loadUsers" @clear="loadUsers"/>
      </div>
      <div class="column is-12">
        <v-loader v-if="isLoading"/>
        <b-table
            paginated
            backend-pagination
            backend-filtering
            :total="pagination.total"
            :per-page="pagination.per_page"
            :data="users"
            @page-change="onPageChange">
          <b-table-column field="id" label="ID" width="40" numeric v-slot="{row}">
            {{ row.id }}
          </b-table-column>
          <b-table-column field="full_name" label="Nombre" v-slot="{row}">
            {{ row.full_name }}
          </b-table-column>
          <b-table-column field="email" label="E-mail" v-slot="{row}">
            {{ row.email }}
          </b-table-column>
          <b-table-column label="Órdenes" v-slot="{row}">
            <router-link to="" class="button is-small is-primary" :disabled="!row.orders_count">
              {{ row.orders_count }}
            </router-link>
          </b-table-column>
          <b-table-column label="Tipo" v-slot="{row}">
            <div class="tags">
              <span class="tag is-light" :class="{'is-success': isAdmin(row), 'is-warning': !isAdmin(row)}">
                {{ row.roles | parseUserRoles }}
              </span>
              <span class="tag is-danger is-light" v-if="!row.is_active">
                Desactivado
              </span>
            </div>
          </b-table-column>
          <b-table-column label="Acciones" v-slot="{row}">
            <div class="buttons has-addons" v-if="row.is_active">
              <router-link :to="{ name: 'edit_user', params: { id: row.id } }" class="button is-small is-primary">Editar</router-link>
              <button class="button is-small is-danger" @click="promptDisable(row)">Desactivar</button>
            </div>
            <button class="button is-small is-warning" @click="promptEnable(row)" v-else>Reactivar</button>
          </b-table-column>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UsersList',
  data() {
    return {
      isLoading: false,
      pagination: {
        page: 1,
        per_page: 10,
        total: 0
      },
      users: []
    }
  },
  methods: {
    isAdmin(user) {
      return user.roles && user.roles.findIndex(role => {
        return role.name === 'admin';
      }) > -1;
    },
    enableUser(userId) {
      this.isLoading = true;
      this.$api.users.updateUser(userId, {
        is_active: true,
      })
      .then(() => {
        this.loadUsers();
        this.$buefy.toast.open({
          message: 'Usuario activado',
          type: 'is-success'
        })
      })
    },
    disableUser(userId) {
      this.isLoading = true;
      this.$api.users.updateUser(userId, {
        is_active: false,
      })
      .then(() => {
        this.loadUsers();
        this.$buefy.toast.open({
          message: 'Usuario desactivado',
          type: 'is-success'
        })
      })
    },
    promptEnable(user) {
      this.$buefy.dialog.confirm({
        title: `Activar usuario`,
        message: `¿Activar usuario <b>${user.full_name}</b>?`,
        confirmText: 'Activar',
        type: 'is-warning',
        hasIcon: true,
        onConfirm: () => this.enableUser(user.id)
      })
    },
    promptDisable(user) {
      this.$buefy.dialog.confirm({
        title: `Desactivar usuario`,
        message: `¿Desactivar usuario <b>${user.full_name}</b>? <br> El usuario no podrá ingresar a la plataforma`,
        confirmText: 'Desactivar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.disableUser(user.id)
      })
    },
    onPageChange(page) {
      this.pagination.page = page;
      this.loadUsers()
    },
    loadUsers(search = '') {
      this.isLoading = true;
      this.$api.users.getUsers({
        page: this.pagination.page,
        perPage: this.pagination.per_page,
        q: search
      })
      .then(res => {
        const { data, ...pagination } = res;
        this.users = data;
        this.pagination = pagination;
      })
      .finally(() => {
        this.isLoading = false;
      })
    }
  },
  mounted() {
    this.loadUsers();
  }
}
</script>
